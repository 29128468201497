.DatasetDataTab {
    height: 100%;

    .DataTable {
        height: calc(100% - 111px);
    }

    .JoinedDatasetSetup {
        margin-bottom: 30px;
    }
}

.DatasetDataTab__details {
    display: flex;
    margin: 20px 0 30px;

    .Input {
        flex: 1;
        margin-right: 30px;

        &:last-child {
            flex: 2;
            margin-right: 0;
        }
    }
}
