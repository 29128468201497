@import '../../../../styles/partials/index';

.ModelPanel {
    @extend %boxShadowLight;

    position: relative;

    // model panel is styled so that it does not scroll, but the sections inside both resize & scroll
    display: flex;
    flex-flow: column;
    height: 100%;

    width: 240px;
    padding: 20px;

    border: 1px solid $neutral-90;
    background: $white;

    .CollapsibleSection {
        // TODO DSP-3172: Sizes of collapsible sections: if a container scrolls, the scrolling containers should have the same heights
        // I tried to implement the above but couldn't (I couldn't make it work also when containers are small (do not scroll))
        // Current behaviour: sections grow & shrink relative to their sizes.
        margin-top: 14px;
        flex: 0 1 auto;

        &:last-child {
            // Model History section.
            flex: 1 1 300px; // setting flex-basis to 300px to make this section to be smaller than it otherwise would be in most scenarios.  In the odd case where the model has only been run a couple times, this will cause this section to take up some extra space.  As this is the edge case, I left this as it was (couldn't find a good solution)
        }
    }

    .Input, .ConstraintInput {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > .Button {
        // `Run Model` button
        margin-top: 14px;
        width: 100%;
    }

    .SidePanelButton {
        position: absolute;
        top: 61px; // default 61px; usually this will be overwritten with js
        left: -13px;
    }

    > .Loader {
        height: 25px;
    }

    .ButtonWithOptions {
        // in the model list and model history
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ModelPanel__title {
    @extend %bold14;
    color: $neutral-20;
    margin-bottom: 5px;
}

.ModelHistory {
    .Loader {
        margin-top: 10px;
    }
}

.ModelHistory__message {
    margin-bottom: 10px;
    color: $neutral-60;
    font-style: italic;
}

.IconButton--medium {
    width: 30px;
    height: 30px;
    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        border-color: transparent;
        box-shadow: none;
        background-color: transparent;
    }
}