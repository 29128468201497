@import '../../../../../styles/partials/index';

.RootOrganizationView {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.RootOrganizationView__header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    .Icon {
        @include svgColor($blue-500);
        margin-right: 10px;
    }
}

.RootOrganizationView__body {
    flex: 1;
}
