@import '../../../../../styles/partials/index';

.TabItem {
    @extend %shortTransition;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 39px;
    width: fit-content;
    min-width: 60px;

    border-radius: 10px;
    padding: 10px;

    background-color: $white;
    color: $neutral-50;

    .ContextMenu__hook {
        // position the context menu above the icon button
        width: 20px; // matches iconbutton width
        left: unset; // unset to be at the left of the iconbutton
        right: 10px; // 10px matches padding
        top: 10px;
        bottom: 10px;
    }

    &:focus:not(.TabItem--noHover),
    &:hover:not(.TabItem--noHover) {
        background-color: $neutral-95;
        box-shadow: inset 0 0 0 3px $neutral-90;

        padding: 11px;
        margin: 0 -1px;
        z-index: 1;
    }

    &:active:not(.TabItem--noHover) {
        box-shadow: inset 0 0 10px rgba($neutral-70, 0.4), inset 0 0 0 3px $neutral-90;
        transition: none;
    }
}

.TabItem--active {
    color: $blue-600;
}

.TabItem--disabled {
    color: $neutral-80;

    .IconButton {
        @include svgColor($neutral-80);
    }
}

.TabItem__name {
    @extend %bold14;

    max-width: 160px;
    margin-right: 10px;

    text-decoration: none;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
