@import '../../../../styles/partials/index';

.DatasetUpload {
    height: calc(80vh - 50px);
    width: 90vw;
    max-width: 1000px;
    display: flex;
    flex-direction: column;

    hr {
        margin: 30px 0;
    }

    >.Input {
        // file input
        flex: 1;
        height: 100%;
    }
}

.DatasetUpload__config {
    display: flex;

    .Input {
        flex: 1;
        margin-right: 30px;

        &:last-child {
            flex: 2;
            margin-right: 0;
        }
    }
}

.DatasetUpload__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
