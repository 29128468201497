@import '../../../styles/partials/index';

.UnitCard {
    height: 100%;

    &:hover,
    &--open {
        .UnitCard__buttons {
            opacity: 1;
        }

        .EditableText--empty {
            opacity: 1;
        }
    }

    .Card {
        padding: 5px 10px 10px;
        overflow: hidden;
    }
}

.ToolTip > .ToolTip__base {
    width: max-content;
    display: inline-block !important;
    max-width: 400px !important;
    overflow-wrap: break-word;
    white-space: normal;
    display: inline-block !important;
    max-width: 400px !important;
    overflow-wrap: break-word;
    white-space: normal;
}

.UnitCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 35px;

    .Form {
        flex: 0;
        margin-right: auto;
        z-index: 1;
    }

    .Input {
        @extend %bold14;
        flex: 1;
    }

    .Input--disabled {
        opacity: 1;

        .Icon {
            display: none;
        }
    }

    .EditableText--resting .InputBorder {
        color: $neutral-50;
        -webkit-text-fill-color: $neutral-50;
        -webkit-text-fill-color: $neutral-50;
        box-shadow: none;
        background: none;
    }

    .InputBase {
        padding-left: 5px;
    }

    .EditableText--empty {
        @extend %shortTransition;
        opacity: 0;
    }
}

.UnitCard__flags {
    z-index: 2;

    .Icon {
        @include svgColor($neutral-85);
        margin: 2px 0px 0px 0px;
    }
}

.UnitCard__handle {
    height: 40px;
    cursor: pointer;
    position: absolute;
    width: 100%;
    top: -4px;
    left: 0;
}

.UnitCard__buttons {
    @extend %shortTransition;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    min-width: 66px;
    opacity: 0;
    align-items: center;
    position: relative;

    .ContextMenu__hook {
        left: unset;
        width: 30px;
    }
}

.UnitCard__main {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}

.UnitCard__description {
    .Form {
        margin: 5px 0;
        flex: 0 0 auto;
    }

    .Input {
        flex: 1;
        overflow-wrap: break-word;
    }

    .TextArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;

        textarea {
            @extend %regular12;
            color: $neutral-70;
            padding-left: 7px;
            height: auto;
        }

        textarea:hover {
            transition: all 0.2s ease;
        }

        textarea:focus {
            color: $neutral-20;
            transition: all 0.2s ease;
        }
    }
}

.UnitCard__menu {
    width: 150px;
}

/* Fix .Unit */
/* Fix .Unit */
.Unit {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: auto !important;
    min-height: 100px !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    white-space: nowrap;
}


.Unit::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}