
@import '../../../../../../styles/partials/index';

.ConstraintInput {
    position: relative; // keeps context menu in the right place
    cursor: pointer;
}

.ConstraintInput__input {
    display: flex;
    align-items: center;

    &:focus {
        outline: none;
    }
}

.ConstraintInput__menu {
    width: 260px;
}

.ConstraintInput__menuItems {
    max-height: 300px;
    overflow: auto;

    .ContextMenu__subMenu {
        padding-top: 8px;
    }
}

.ConstraintInput__minMax {
    display: flex;
    flex-wrap: nowrap;

    > .Input {
        margin-left: 5px;
    }
}

.ConstraintInput__ignoreAll {
    margin: 5px 8px 8px;
}
