@import '../../../../../../styles/partials/index';

.OrganizationUsers__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend %bold18;
    padding-top: 20px;
    padding-bottom: 30px;
}

.OrganizationUsers__Search {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    .InputBorder--gray {
        background-color: $white;
    }
}

.OrganizationUsers__List {
    display: flex;
    flex-direction: column;
    max-width: 830px;
    margin: 0 auto;

    > * + * {
        margin-top: 15px;
    }
}

.OrganizationUsers__empty {
    @extend %regular14;
    margin: 50px auto;
}
