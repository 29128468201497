@import '../../../../styles/partials/index';

.DatasetType {
    @extend %regular14;
    @include svgColor($neutral-85);
    align-items: center;
    color: $neutral-60;
    display: flex;

    .Icon {
        margin-right: 5px;
    }

    a {
        font: inherit;
        margin-left: 2px;
    }
}
