@import '../../../../styles/partials/index';

.RunModel {
    height: 100%;
    width: 100%;
    display: flex;

    .Card {
        width: calc(100% - 60px); // 60px from margins
        height: calc(100% - 60px); // 60px from margins
        margin: 30px;
        padding: 0;
    }

    .ModelPanel {
        box-shadow: none;
        border-width: 0 1px;
    }
}

.RunModel__prompt,
.RunModel__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 85px auto 0;
}

.RunModel__status {
    @extend %bold18;

    .Icon {
        margin-bottom: 10px;
    }

    .Button {
        margin-top: 5px;
    }

    .ModelRunErrors {
        overflow: auto;
        height: auto;
        font-family: Courier;
        text-align: left;
        font-size: 14px;
        background-color: $neutral-95;
        border-radius: 5px;
        margin: 20px;
        padding: 20px;
    }

    .ModelRunErrors__line {
        // @extend %regular14;
    }
}

.RunModel__status--running .Icon {
    @include svgColor($blue-300);
    animation: rotate 2s linear 0s infinite;
}

.RunModel__status--error .Icon {
    @include svgColor($red-400);
}

.RunModel__prompt {
    @extend %regular14;
    max-width: 250px;
}
