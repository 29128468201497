@import '../../../../styles/partials/index';

.JoinedDatasetWorkflow {
    height: calc(80vh - 50px);
    width: 90vw;
    max-width: 1000px;

    hr {
        margin: 30px 0;
    }
}

.JoinedDatasetWorkflow__messageIcon {
    @extend %regular14;
    width: 260px;
    margin: 0 auto;
    text-align: center;
    color: $neutral-40;

    strong {
        @extend %bold14;
        color: inherit;
    }

    .Icon {
        margin: 10px auto 0;
        display: block;
    }

    .JoinedDatasetWorkflow__diagram {
        margin-top: 50px;
    }
}

.JoinedDatasetWorkflow__create {
    display: flex;

    .JoinedDatasetWorkflow__nameAndCollection {
        display: flex;
        width: 50%;

        .Input {
            flex: 1;
            margin-right: 30px;
    
            &:last-child {
                margin-right: 15px;
            }
        }
    }

    .JoinedDatasetWorkflow__description {
        margin-left: 15px;
        flex: 1;
        width: 50%;
    }
}

.JoinedDatasetWorkflow__labelWithDot label::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $orange-300;
}

.JoinedDatasetWorkflow__labelWithDot--secondary label::before {
    background-color: $green-300;
}

.JoinedDatasetWorkflow__datasets,
.JoinedDatasetWorkflow__columns {
    display: flex;
    width: 50%;
    margin-bottom: 20px;
    position: relative;

    .Input {
        flex: 1 1 auto;
        margin-right: 15px;

        &:first-child {
            margin-right: 0;
        }
    }

    .IconButton {
        position: absolute;
        bottom: 5px;
        right: -20px;
    }
}

.JoinedDatasetWorkflow__plus {
    @extend %bold18;
    color: $neutral-70;
    width: 30px;
    text-align: center;
    line-height: 40px;
    align-self: flex-end;
}

.JoinedDatasetWorkflow__title {
    @extend %bold14;
    color: $neutral-20;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
   
}

.JoinedDatasetWorkflow__selectors {
    display: flex;
    margin-top: 30px;

    > * + * {
        margin-left: 30px;
    }
}

.JoinedDatasetWorkflow__selector {
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 8px 25px;
    flex: 1;
    background-color: $neutral-98;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $neutral-95;
    }

    .Icon {
        margin-right: 15px;
    }
}

.JoinedDatasetWorkflow__selector--selected {
    box-shadow: inset 0 0 0 2px $blue-200;
    background-color: $neutral-95;
}

.JoinedDatasetWorkflow__selector__description {
    display: flex;
    flex-direction: column;

    span {
        @extend %bold14;
        flex: 1;
        color: $neutral-40;
    }

    span:last-child {
        @extend %regular14;
        color: $neutral-60;
        padding-top: 2px;
    }
}
