@import '../../../../../styles/partials/index';


.FeaturePreview {
    min-height: 242px;
    margin-top: 15px;
    width: 100%;

    header {
        @extend %bold14;
        color: $neutral-40;
        margin-bottom: 5px;
    }

    span {
        @extend %regular12;
        font-style: italic;
        color: $neutral-60;
    }

    .DataTable {
        padding: 30px 52px;
    }
}

.FeaturePreview__background {
    background-color: $neutral-98;
    border: 1px solid $neutral-90;
    border-radius: 15px;
    margin-top: 10px;
    box-shadow: 0 0 4px rgba($neutral-20, 0.1);
}
