@import '../../../../../styles/partials/index.scss';

.AccordianItem {
    padding: 9px 20px;
    background-color: $white;
    box-shadow: 0 0 4px rgba(33, 48, 77, 0.1);
    border: 1px solid $neutral-90;
    border-radius: 15px;
    margin: 15px 0 1px;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }
}

.AccordianItem__body {
    @extend %regular12;
    display: flex;
    align-items: center;
    outline: none;

    .UserIcon,
    .UserIcon--logo,
    .Logo {
        width: 34px;
        height: 34px;
        margin-right: 20px;
        align-items: center;
    }

    .UserIcon__text {
        margin: 7px auto;
    }

    .EditMembers__SelectRoleInput {
        width: 170px;
    }
}

.AccordianItem__permissionButton {
    margin: 0 0 0 7px;
}

.AccordianItem__content {
    flex: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: unset;
}

.AccordianItem__details {
    display: flex;
    align-items: center;
    flex-direction: unset;
}

.AccordianItem__name {
    @extend %bold14;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $neutral-20;
}

.AccordianItem__yline {
    border-left: 1px solid $neutral-80;
    height: 14px;
    margin: 0 10px;
}

.AccordianItem__text {
    @extend %regular14;
    color: $neutral-60;
}

.AccordianItem__userEmail {
    @extend %regular14;
    color: $neutral-30;
}

.AccordianItem__userRoleinfo {
    @extend %regular12;
    color: $neutral-60;
    width: 200px;
    text-align: right;
    margin-right: 20px;
}

.AccordianItem__info {
    @extend %regular14;
    color: $neutral-60;
}

.AccordianItem__menuIcon {
    margin-left: 5px;
    color: $neutral-60;
}

.AccordianItem--selected {
    box-shadow: inset 0 0 0 2px $blue-200;
}

.AccordianItem--deactivated {
    .AccordianItem__name,
    .AccordianItem__text,
    .AccordianItem__info,
    .AccordianItem__menuIcon {
        opacity: 0.6;
    }
}

.AccordianItem--permissions .PermissionTable {
    margin-top: 10px;
}
