@import '../../../../styles/partials/index';

.TableUnitMapping {
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    flex: 1;
    overflow: auto;

    .TableUnit {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .UnitLoader {
        max-height: calc(100% - 80px); // height & margin for MappingBucket
    }

    .MappingBucket {
        margin-top: 10px;
    }

    .TableUnitMapping__preview {
        border: 1px solid $neutral-90;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $white;
        overflow: hidden;

        > main {
            flex: 1;
            height: fit-content;
            max-height: calc(100% - 40px); // 100% minus header height
            position: relative;
            border-top: 1px solid $neutral-90;
        }

        > header {
            flex: 0 0 30px;
            background-color: $white;
            border-radius: 6px;
            height: 40px;
            min-height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 10px;
        }
    }
}

.TableUnitBasket {
    width: 175px;
}
