@import '../../../../../styles/partials/index';

.UserIcon {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 1px solid $neutral-70;
    position: relative;
}

.UserIcon__text {
    width: fit-content;
    margin: auto;
    color: $white;
    line-height: 20px;
}

.UserIcon--logo {
    position: relative;
    .Logo {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 1px solid $neutral-70;
        position: relative;
    }
}

.UserIconLogo--large {
    .Logo {
        width: 40px;
        height: 40px;
    }
}

.UserIcon--large {
    width: 40px;
    height: 40px;

    .UserIcon__text {
        margin-top: 10px;
    }
}
