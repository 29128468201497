@import '../../../styles/partials/index';

.DataSidebar {
    display: flex;
    flex-direction: column;
    background-color: $neutral-98;
    height: 100%;
    width: inherit;

    // Overwrite styles for List items in side bar
    .ListItem {
        @extend %semibold14;
        padding: 5px 2px;
        border-radius: 10px;
        overflow: hidden;

        &:active {
            background-color: $neutral-95;
            color: inherit;

            .Icon {
                @include svgColor($neutral-85);
                color: $neutral-85;
            }
        }
    }

    .DragAndDropList__type {
        @include svgColor($blue-200);
        color: $blue-200;
    }
    .DragAndDropList__title {
        overflow: auto;
    }

    .ScrollArea__topGradient {
        background: linear-gradient(to bottom, $neutral-98, rgba($neutral-98, 0));
    }

    .ScrollArea__bottomGradient {
        background: linear-gradient(to top, $neutral-98, rgba($neutral-98, 0));
    }
}

.DataSidebar__content {
    height: auto;
    overflow: auto;
    max-width: 100%;
    padding: 0 15px 0 30px;

    @include tablet {
        padding: 0 30px;
    }

    @include phone {
        padding: 0 30px;
    }
}

.DataSidebar__title {
    @extend %bold18;
    border-bottom: 1px solid $neutral-90;
    display: flex;
    height: 40px;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
}

.DataSidebar__emptyPlaceholderTitle {
    @extend %semibold14;
    text-align: center;
    margin: 25px 15px 5px;
}

.DataSidebar__emptyPlaceholderDescription {
    color: $neutral-70;
    text-align: center;
    margin: 5px 10px;
}

.Data__searchBar {
    margin: 20px 10px 0 30px;

    @include tablet {
        margin: 20px 30px 0 30px;
    }

    @include phone {
        margin: 20px 30px 0 30px;
    }
}
