@import '../../../../../styles/partials/index';

.SectionListItem {
    display: flex;
    position: relative;
    align-items: center;
    min-height: 40px;
    justify-content: space-between;

    // Removes upper and lower lines of first child and last child
    // .SectionListItem__:first-child {
    //     .SectionListItem__lines:first-child {
    //         .SectionListItem__line:first-child {
    //             border-right: solid 0px;
    //         }
    //     }
    // }
    // .SectionListItem__item:last-of-type {
    //     .SectionListItem__lines {
    //         .SectionListItem__line:last-child {
    //             border-right: solid 0px;
    //         }
    //     }
    // }
}

.SectionListItem--complete {
    .SectionListItem__circle {
        background-color: $blue-300;
    }
    .SectionListItem__line {
        color: $blue-300;
    }
}

.SectionListItem:hover,
.SectionListItem--active {
    @extend %cursor-canDrag;
    border-radius: 5px;
    background-color: $neutral-95;
}

.SectionListItem--upcoming {
}

.SectionListItem--active {
    .SectionListItem__label {
        @extend %semibold14;
        color: $neutral-40;
    }
    .SectionListItem__circle {
        background-color: $blue-300;
    }
    .SectionListItem__line:last-child {
        color: $neutral-90;
    }
}

.SectionListItem--dragging {
    @extend %cursor-isDragging;
    opacity: 0.1;
    background-color: blue;
}

.SectionListItem__body {
    display: flex;
    align-items: center;
}

.SectionListItem__lines {
    display: flex;
    width: 16px;
    flex-direction: column;
}

.SectionListItem__line {
    color: $neutral-90;
    border-right: solid 1px;
    flex-grow: 1;
    height: 20px;
}

.SectionListItem__circle {
    height: 11px;
    width: 11px;
    background-color: $neutral-90;
    border-radius: 50%;
    left: 10px;
    position: absolute;
}

.SectionListItem__label {
    @extend %regular14;
    color: $neutral-60;
    margin-left: 15px;
    overflow: hidden;
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.SectionListItem__menu {
    margin-right: 5px;
}
