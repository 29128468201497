@import '../../../../../styles/partials/index';

.ConsultantOrganizationsList {
    height: 100%;
}

.ConsultantOrganizationsList__header {
    height: 50px;

    .Input {
        width: 300px;
    }
}

.ConsultantOrganizationsList__prompt {
    @extend %regular14;
    max-width: 255px;
    margin: 0 auto 30px;
    text-align: center;
    .Icon {
        height: 100px;
        min-height: unset;
    }
}

.ConsultantOrganizationsList__body {
    max-width: 830px;
    margin: 0 auto;
}
