@import '../../../../styles/partials/index';

.ChartMapping {
    display: flex;
    height: 100%;
    .ChartMapping__AxisContainer__ScrollArea {
        max-width: 240px;
        margin-right: -15px;
    }
}

.ChartMapping__settings {
    position: relative;
    box-shadow: 0 4px 6px rgba($neutral-20, 0.2);
    margin: -25px -15px -215px 0;
    z-index: 1;
}

.ChartMapping--settingsOpen {
    .ChartMapping__Chart {
        margin-left: 0;
    }
}

.ChartMapping__AxisContainer {
    display: flex;
    flex-direction: column;

    .MappingBucket {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.ChartMapping__AxisContainer--right {
    overflow-y: auto;
}

.ChartMapping__Chart {
    flex: 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .MappingBucket {
        flex: 0 0 92px;
        margin-top: 15px;
    }
}

.ChartMapping__yAxis {
    height: 100%;
}
