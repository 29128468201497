.CustomSubtypeMapping {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.CustomSubtypeMapping__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
        margin: 15px;
    }
}

.CustomUnitBasket__config {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    .Chip {
        margin: 0 3px;
    }
}
