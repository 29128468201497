@import '../../../../styles/partials/index';

.Login_Reset {
    .Input {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
