@import '../../../styles/partials/index';

.SelectableBar {
    @extend %regular12;
    display: flex;
    align-items: center;
    height: 50px;

    padding: 10px;
    background-color: $neutral-98;
    border-radius: 10px;

    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
        background-color: $neutral-95;
    }
}

.SelectableBar__content {
    flex: auto;
    overflow: hidden;
    display: flex;
    align-items: center;

    .SelectableBar__details {
        display: flex;
        align-items: center;
    }
}

.SelectableBar__name {
    @extend %bold14;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $neutral-30;
    margin-left: 10px;
}

.SelectableBar__yline {
    border-left: 1px solid $neutral-80;
    height: 20px;
    margin: 0 10px;
}

.SelectableBar__text {
    @extend %regular14;
    color: $neutral-60;
}

.SelectableBar__info {
    @extend %regular14;
    color: $neutral-60;
    margin-right: 5px;
}

.SelectableBar__menuIcon {
    color: $neutral-60;
}

.SelectableBar--child {
    margin-left: 50px;
    height: 36px;
}

.SelectableBar--selected {
    box-shadow: inset 0 0 0 2px $blue-200;
}

.SelectableBar--deactivated {
    .SelectableBar__name,
    .SelectableBar__text,
    .SelectableBar__info,
    .SelectableBar__menuIcon {
        opacity: 0.6;
    }
}
