@import '../../../../styles/partials/index';

.MappingBucket {
    display: flex;
    align-items: stretch;
    border-radius: 10px;
    max-height: 100%;
    position: relative;
}

.MappingBucket__header {
    @extend %semibold14;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    background-color: $white;
    color: $neutral-40;
    border: 1px solid $neutral-90;
    padding: 0 10px;

    word-break: break-word; // not supported in Edge/IE
    overflow-wrap: break-word; // this is a fallback for Edge/IE
}

.MappingBucket__title {
    margin-left: 5px;
    cursor: default;
}

.MappingBucket--required .MappingBucket__title::after {
    content: ' *';
    color: $neutral-70;
}

.MappingBucket__main {
    flex-grow: 1;
    display: flex;
    background-color: transparent;
    border: 1px solid $neutral-90;
    overflow: auto; // keeps bucket from overflowing when there are many nuggets
    position: relative;
    background-color: $neutral-98;
    transition: border 0.15s ease-in;
}

.MappingBucket--empty .MappingBucket__main {
    border-style: dashed;
}

.MappingBucket--droppable .MappingBucket__main {
    border-color: $blue-400;
}

.MappingBucket__content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MappingBucket__placeholder {
    @extend %regular14;
    height: 100%;
    width: 100%;
    min-height: 50px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral-60;
    pointer-events: none;
    text-align: center;
}

// ----------- Vertical specific styles ------------
.MappingBucket--vertical {
    flex-direction: column;
    width: 220px;

    .MappingBucket__header {
        width: 100%;
        min-height: 50px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .Input {
            width: 100%;
        }
    }

    .MappingBucket__main {
        flex-direction: column;
        border-top-width: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .MappingBucket__content {
        flex-flow: column;
        width: 100%;
    }

    .FeatureBasket {
        margin-top: 10px;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    .MappingBucket__placeholder {
        max-width: 200px;
        margin: 0 auto;
    }
}

// -------------- collapsed style --------------
.MappingBucket--collapsed {
    min-height: auto;

    .MappingBucket__header {
        border-radius: 10px;
    }
}

// -------------- horizontal specific styles -----------
.MappingBucket--horizontal {
    min-height: 70px;

    .MappingBucket__header {
        min-width: 125px;
        border-top-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 0;
    }

    .MappingBucket__main {
        border-left-width: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;
    }

    .FeatureBasket {
        margin-left: 10px;

        &:last-child {
            margin-right: 10px;
        }
    }

    .MappingBucket__placeholder {
        white-space: nowrap;
    }
}
