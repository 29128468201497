@import '../../../styles/partials/index';

.Dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .DashboardTab {
        margin: 10px 30px 30px;

        @include phone {
            margin: 10px 25px;
        }
    }

    .TabBar {
        position: absolute;
        bottom: 0;
        z-index: 40;
    }

    .ModelPanel {
        margin-top: -10px;
        padding-top: 30px; //10px more than default
        height: calc(100% + 10px);
    }
}

.Dashboard__body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden; // model bar animating out was causing this component to scroll
}

.DashboardEmpty {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $neutral-80;

    .Icon {
        @include svgColor($neutral-90);
    }
}

.Dashboard_Loaders {
    .Loader {
        height: 400px;
        margin: 0;
        border-radius: 4px;

        &:nth-child(2) {
            margin-top: 0;
        }
    }
}
