@import '../../../../../styles/partials/index';

.DashboardColors {
    width: 190px;
    overflow: auto;
}

.DashboardColors__overrides {
    max-height: 275px;
    margin-top: -1px;
    overflow: auto;
}

.DashboardColors__themesList {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: space-around;
    height: fit-content;
    max-height: 130px;
    padding: 8px;
    border: 1px solid $neutral-70;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba($neutral-20, 0.25);
    margin: -1px;
}


.DashboardColors__themePreview {
    display: flex;
    justify-content: space-between;
    height: 36px;
    width: 100%;
    padding: 8px;
    box-shadow: inset 0 0 0 1px $neutral-80;
    border-radius: 5px;
    background-color: $neutral-50;
    align-items: center;
    margin: 4px 0;
    outline: none;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 0 0 3px $neutral-80;
    }

    &--selected,
    &--selected:hover {
        box-shadow: inset 0 0 0 3px $blue-300;
        background-color: $neutral-98;

        .DashboardColors__themePreviewColor {
            border-color: $neutral-80;
        }
    }
}

.DashboardColors__themePreviewColor {
    height: 20px;
    width: 20px;
    border-radius: 8px;
    border: 1px solid $neutral-98;
}

.DashboardColors__themePreviewColorHex {
    margin-right: auto;
    margin-left: 5px;
    text-transform: uppercase;
}

.DashboardColors__icon {
    @include svgColor($white);
}
