@import '../../../../styles/partials/index';

.PublishModal {
    min-width: 90vh;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    hr {
        width: 100%;
    }
}

.PublishModal__warningTitle {
    @extend %bold24;
    margin-bottom: 10px;
}

.PublishModal__warningSubtitle {
    @extend %regular14;
}

.PublishModal__warningIcon {
    @include svgColor($orange-300);
}

.PublishModal__preview {
    width: calc(100% - 60px);
    margin: 10px 30px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}
