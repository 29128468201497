@import '../../../../../styles/partials/index';

.ChangeFeatureTypeModal {
    min-width: 550px;
    height: 680px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;

    hr {
        width: 100%;
    }

    .Input {
        width: 100%;
    }
    
}

.ChangeFeatureTypeModal__warningIcon {
    @include svgColor($orange-300);
}

.ChangeFeatureTypeModal__warningText {
    @extend %regular14;
    width: 260px;
    text-align: center;
    margin-bottom: 20px;
}



.ChangeFeatureTypeModal__preview {
    width: 100%;
    margin: 10px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
