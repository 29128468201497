@import '../../../styles/partials/index';

.ReportCard {
    height: auto;
    scroll-margin-top: 148px;

    &:hover,
    &--open {
        .ReportCard__buttons {
            opacity: 1;
        }

        .EditableText--empty {
            opacity: 1;
        }
    }

    .Card {
        padding: 5px 10px 10px;
        overflow: hidden;
        position: relative;
    }
    padding-bottom: 30px;
}

.ReportCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 35px;
    z-index: 2;
    margin: 10px 0 0 5px;
    font-size: 20px;

    .Icon {
        @include svgColor($neutral-85);
        margin-right: 5px;
    }
    .Icon--selected {
        @include svgColor($blue-300);
        margin-right: 5px;
    }
}

.ReportCard__main {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    margin-top: 0px;
}

.ReportCard__ReportUnit {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    padding: 30px;
    padding-bottom: 60px;

    .ReportCard__unitHeader {
        height: 30px;
        position: absolute;
        right: 5px;
        top: 5px;
        opacity: 0;
    }

    &:hover {
        border: 1px solid $neutral-90;
        border-radius: 10px;
        padding: 30px;
        padding-bottom: 60px;

        .ReportCard__unitHeader {
            top: 4px;
            right: 4px;
            opacity: 1;
        }
    }
}

.ReportUnit {
    height: 0;
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .Placeholder {
        height: 200px;
    }

    .Chart {
        min-height: 100px;
        height: 450px;
    }

    .CustomUnitMarkdown {
        padding: 0;
    }

    .TableUnit {
        height: 450px;
    }

    .Table__Buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        z-index: 10;
        position: relative;
        bottom: 0px;
        right: -10px;
        padding-top: 10px;
    }
}

.Report__AddCard {
    border: unset;
    background-color: $white;
    min-width: calc(100% - 60px);
    min-height: 50px;
    margin: 0 10px 0px 10px;
    box-shadow: unset;

    .Report__AddCard__Button {
        @include svgColor($neutral-98);
    }

    &:hover {
        background-color: $neutral-98;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @extend %shortTransition;
        height: inherit;
        border-radius: 10px;
        width: inherit;
        margin: 0 30px 20px 30px;

        .Report__AddCard__Button {
            @include svgColor($blue-400);
            border: 2px solid $blue-400;
            display: block;
            margin: auto;
            padding: 15px;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                @extend %shortTransition;
                background: $neutral-98;
                border: 2px solid $blue-400;
                outline: none;
            }
        }
    }
}

.Report__EmptyCard {
    min-height: 280px;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Report__EmptyCard__Text {
        @extend %regular14;
        margin-top: 15px;
        color: $neutral-70;
    }

    .Report__EmptyCard__Button {
        @include svgColor($neutral-70);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $neutral-90;
        border-radius: 100%;
        width: 65px;
        height: 65px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            @extend %shortTransition;
            @include svgColor($blue-400);
            background: $neutral-98;
            border: 2px solid $blue-400;
            outline: none;
        }
    }
}
