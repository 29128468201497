@import '../../../../styles/partials/index';

.DetailHeader__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;

    > .Icon,
    > .IconButton {
        margin-left: 5px;
    }
}

.DetailHeader__left-arrow {
    position: absolute;
    left: 25px;
    top: 15px;
}
