@import '../../../styles/partials/index';

.DataAdd {
    display: flex;
    height: 80vh;
    width: 90vw;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
}

.DataAdd__choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 260px;
    margin: 15px;
    border-radius: 14px;
    background: $neutral-98;
    border: 0;
    outline: none;
    cursor: pointer;

    &:hover {
        background: $neutral-95;
    }

    &:active {
        border: 2px solid $blue-200;
    }
}

.DataAdd__title {
    @extend %bold18;
    color: $neutral-40;
}

.DataAdd__subtitle {
    @extend %regular14;
    color: $neutral-60;
}
