@import '../../../../styles/partials/index';

.CodeModal {
    .Input__container {
        height: 100%;
        width: 100%;
    }

    #sourceFileName {
        width: 250px;
    }
}

.CodeModal__header {
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
}

.CodeModal__warningMessage {
    @extend %regular14;
    display: flex;

    align-items: center;
    color: $neutral-40;

    .Icon {
        @include svgColor($amber-600);
        margin-right: 5px;
    }
}

.CodeModal__codeBox {
    margin: 15px 10px 5px;

    .CodeMirror {
        border: unset;
        width: 85vw; //anything higher than this adds a second scrollbar??
    }
    .Codebox {
        .cm-s-default,
        .CodeMirror-wrap,
        .CodeMirror-scroll,
        .CodeMirror {
            max-height: 72vh;
            min-height: 50vh;
        }

        .CodeMirror,
        .CodeMirror-gutters {
            background-color: $neutral-98;
        }
        .CodeMirror {
            border: 1px solid #dfe2ee;
        }
    }
}
