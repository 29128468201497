@import '../../../../styles/partials/index';

.IconButtonFilter__contextMenu {
    width: 250px;

    .ContextMenuItem {
        @include svgColor($white);
        border-radius: 5px;
        padding: 8px;
    }

    .ContextMenuItem__name {
        padding-left: 6px;
    }

    .ContextMenuItem__type {
        width: 16px;
    }

    .Filter__Display {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 40px;

        > div {
            flex: 1 auto;
            margin: 1px;
        }

        .Icon {
            margin: 0 8px;
        }
    }

    .Filter__Label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;
        color: $white;

        .Filter__Label--bold {
            @extend %regular12;
        }

        .Filter__Label--normal {
            @extend %regular12;
            font-style: italic;
        }
    }

    .Filter__Value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 205px;
        color: $white;
    }

    .Filter__Value--icon {
        @include svgColor($neutral-90);
        right: 0;
        position: absolute;

        .Icon {
            top: 10px;
            position: relative;
        }
    }
}
