@import '../../../../../../styles/partials/index';

.UserLoginTimeline {
    margin: 20px 0;
    overflow: auto;
    max-height: 65vh;
}

.UserLoginTimeline__title {
    @extend %bold18;
    color: $neutral-40;
    margin-bottom: 3px;
}

.UserProfile {
    max-width: fit-content;
    margin: 0 auto;
}

.UserProfile__info {
    display: flex;
    margin-top: 30px;
    .Input {
        width: 275px;
    }
    > * + * {
        margin-left: 30px;
    }
}

.UserProfile__picture {
    @extend %semibold14;
    color: $neutral-40;
    display: flex;
    flex-direction: column;
    width: 91px;
    margin-top: 30px;
    .ToolTip {
        margin-right: auto;
    }
}

.UserPassword {
    display: flex;
    flex-direction: column;

    .Input,
    .Notification {
        margin: 20px 15px 20px 0;
        width: 275px;
    }

    .Button,
    .Validations {
        margin-bottom: 20px;
    }
}

.UserPassword__title {
    @extend %bold18;
    margin-top: 20px;
}

.UserPassword__newPassword {
    display: flex;
}

.UserTabs__form {
    .Input {
        width: 275px;
    }
}
