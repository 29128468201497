@import '../../../../../../styles/partials/index';

.AddPlaceholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    width: 100%;
    height: inherit;
    border-radius: 11px;
    width: inherit;

    .AddPlaceholder__title {
        @extend %bold18;
        margin-top: 8px;
        color: $neutral-70;
    }

    .AddPlaceholder__subtitle {
        @extend %regular12;
        margin-top: 5px;
        color: $neutral-70;
        margin-bottom: 30px;
    }

    .GridPlaceholder__button {
        margin: 0;
    }
}

.GridPlaceholder {
    transition: 0.5s background-color, 0.5s opacity;
    display: flex;
    opacity: 0;
    align-items: center;
    border-radius: 10px;
    position: relative;
    height: inherit;
    width: inherit;

    &:hover {
        transition: 0.5s background-color, 0.5s opacity;
        background-color: $neutral-95;
        opacity: 1;
    }
}

.GridPlaceholder__button {
    @include svgColor($neutral-80);
    display: block;
    margin: auto;
    padding: 20px;
    border: 1px solid $neutral-80;
    border-radius: 100%;
    width: 65px;
    height: 65px;
    cursor: pointer;

    i {
        top: 2px;
    }

    &:hover,
    &:focus,
    &:active {
        @extend %shortTransition;
        @include svgColor($blue-400);
        background: $neutral-95;
        border: 1px solid $blue-400;
        outline: none;
    }
}
