@import '../../../../../styles/partials/index';

.UploadPackageModal {
    display: flex;
    flex-direction: column;
    min-width: 560px;

    > :not(:last-child) {
        margin-bottom: 10px;
    }
}
