@import '../../../../styles/partials/index';

.Filter {
    @extend %shortTransition;
    @extend %regular14;
    @include svgColor($neutral-70);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;

    padding: 0 15px;
    min-width: max-content;

    text-align: start;

    color: $neutral-70;
    background-color: $white;
    border-radius: 9px;

    outline: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.Filter--menuOpen {
        background-color: $neutral-95;
        margin: 0 -1px;
        padding: 0 16px;
    }

    &:hover,
    &:focus {
        box-shadow: inset 0 0 0 3px $neutral-90;
    }

    &:active,
    &.Filter--menuOpen {
        box-shadow: inset 0 0 10px rgba($neutral-70, 0.4), inset 0 0 0 3px $neutral-90;
        transition: none;
        z-index: 1; // active state is on top of hover state
    }
}

.Filter__Display {
    display: flex;
    align-items: center;

    .Icon {
        display: flex;
        margin: 0 0 0 8px;
    }
}

.Filter__Label {
    margin-right: 5px;
    color: $neutral-50;
}

.Filter__Label--bold {
    @extend %bold14;
}

.Filter__Value {
    @extend %bold14;
    color: $blue-500;
    display: flex;
    align-items: center;
}

.Filter--error {
    .Filter__Label {
        @include svgColor($orange-600);
    }

    .Filter__Value {
        color: $orange-600;
    }
}

.Filter--dark {
    @include svgColor($white);
    margin: -1px;

    &:hover,
    &:focus,
    &:active {
        background-color: $neutral-50;
        box-shadow: inset 0 0 0 3px $neutral-80;
        z-index: auto;
        padding: 0 0 0 10px;
        margin: 0;
    }

    &:active,
    &.Filter--menuOpen {
        box-shadow: inset 0 0 12px rgba($white, 0.4), inset 0 0 0 3px $neutral-80;
        background-color: $neutral-50;
        padding: 0 0 0 10px;
        margin: 0;
        transition: none;
    }
}

.Filter--arrayDisplay {
    background-color: transparent;
    min-height: 50px;
    justify-content: flex-start;
    padding: 0 0 0 10px;
    margin: 0;
    border-radius: 3px;
    z-index: auto;
}
