@import '../../styles/partials/index';

.App {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.App__body {
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;

    > .Loader {
        margin-top: -10px;
    }
}
