@import '../../../../styles/partials/index';

.SettingsMapping {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $neutral-90;
    border-radius: 0;
    padding: 40px 0 20px 30px;
    background-color: $white;

    .SidePanelButton {
        position: absolute;
        left: -13px;
        top: 90px;
    }

}

.SettingsMapping__ResetDefaults {
    display: flex;
    margin-top: 30px;
    margin-right: 10px;
    justify-content: space-between;
}

.SettingsMapping__header {
    @extend %bold20;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .IconButton {
        margin-right: 5px;
        margin-top: -5px;
    }
}


.SettingsMapping__content {
    padding-right: 15px;

    .CollapsibleSection {
        margin-top: 30px;
        flex: 0 1 auto;
        padding-right: 22px;

        &:first-of-type {
            margin-top: 0;
        }

        .CollapsibleSection__title {
            border-bottom-color: $neutral-80;
            max-width: 300px;
        }
    }

    .scrollbar-container {
        padding-right: 15px;
        margin-right: -15px;
    }

    .Input {
        padding-bottom: 10px;
        max-width: 300px;
    }

    .Input__label {
        width: 120px;
    }
}

.SettingsMapping__buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 0 0;

    > * + * {
        margin-left: 10px;
    }
}
