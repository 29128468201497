@import '../../../../styles/partials/index.scss';

.language-toggle {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    gap: 10px;
    position: relative;  // Make sure position is relative so z-index works
    z-index: 10;  // Bring it above other elements
    margin-top: 10px;  // Add some margin to ensure it doesn't overlap the card
}
  
.language-toggle button {
    // margin-left: 10px;
    // padding: 5px 10px;
    // border: 1px solid #ccc;
    // background-color: #f0f0f0;
    cursor: pointer;
}

.language-toggle button:disabled {
    // background-color: #ddd;
    // cursor: not-allowed;
    cursor: pointer;
}
