@import '../../../styles/partials/index';

.ModelDetails {
    height: calc(100% - 40px); //40px for top & bottom margins
    margin: 20px 30px;

    > .Card {
        height: 100%;
        padding: 0;
    }

    .TabHeader {
        margin-bottom: 0;
    }

    .ModelDetails__main {
        display: flex;
        height: calc(100% - 75px); // 20px for tab header, 55 px for detail header
        border-radius: 15px;
        overflow: hidden;
    }

    .ModelInputs {
        padding-top: 10px;
    }
}

.ModelInfo {
    width: 80%;
    max-width: 960px;
    margin: 30px auto;
    color: $neutral-40;
}

.ModelInfo__inputs {
    display: flex;

    .Input {
        flex-grow: 1;

        &:last-child {
            flex-grow: 2;
            margin-left: 10px;
        }
    }
}

.ModelInfo__title,
.ModelInfo__scheduledRunsHeader {
    @extend %bold18;
    display: flex;
    align-items: center;
    margin: 30px 0 10px;

    .Button {
        margin-left: auto;
    }
    .scheduledButton {
        margin-left: 16px;
    }
}

.ModelInfo_noSchedule {
    @extend %regular14;
    font-style: italic;
    color: $neutral-60;
    width: 280px;
}

.ModelInfo__links {
    display: flex;
    flex-wrap: wrap;

    @extend %regular14;
}
.ModelInfo__Schedule {
    .TextButton {
        @extend %semibold14;
    }
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 33.3%;
}
.ModelInfo__link {
    @extend %semibold14;
    margin-bottom: 15px;
    width: 33.3%;
}
