@import '../../../styles/partials/index';

.MappingModal {
    background-color: $neutral-98;
}

.MappingModal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MappingModal__title {
    @extend %bold20;
    flex: 1;
}

.MappingModal__centerHeader {
    // added this in to give a max width to the filter bar
    max-width: 60%;
}

.MappingModal__buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .Button {
        margin-left: 10px;
    }
}

.MappingModal__main {
    max-width: 100%;
    height: calc(100vh - 75px); // 75px is the height of the FullScreenModal header
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}
