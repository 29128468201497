@import '../../../../styles/partials/index';

.FormCard {
    display: inline-block;
    position: relative;
    height: 130px;
    width: 350px;
    min-width: 180px;
    margin-right: 20px;
    margin-bottom: 20px;

    .Card {
        @extend %shortTransition;
        display: flex;
        padding: 0;

        &:hover {
            @extend %boxShadowMedium;
        }
    }
}

.FormCard__main {
    display: flex;
    flex-direction: column;
    width: 100px; // overwritten by flex, but necessary to prevent overflow
    padding: 15px 5px 5px 20px;
    margin-right: -5px; // space for the scrollbar
    margin-bottom: 15px;
    flex: 1;
    cursor: pointer;
    outline: none;
}

.FormCard__title {
    @extend %bold18;
    flex: 0 0 auto;
    color: $blue-500;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.FormCard__description {
    @extend %regular14;

    padding-right: 7px;
    margin-right: -7px;
    color: $neutral-40;
    overflow: auto;
}

.FormCard__sidebar {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.FormCard__status {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: $neutral-60;
    text-align: right;
}

.FormCard__published {
    color: $green-300;
}

.FormCard__closed {
    color: $orange-300;
}

.EditFormModal {
    width: 345px;

    > * {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
