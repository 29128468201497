@import '../../../../styles/partials/';
.EditScheduledModal {
    width: 1380px;
    .TabHeader {
        margin: 0 20px 30px;
    }
    .EditScheduledModalPaused {
        @extend %bold18;
        color: $neutral-40;
        display: flex;
        flex-direction: column;
        align-items: center;
        .resumeTriggerButton {
            margin: 10px 0 0;
        }
    }
    .EditScheduledModalWorkflow {
        height: 600px;
    }
    .EditScheduledModalWorkflowInputs {
        display: flex;
        .Input {
            width: 300px;
            margin: 0 20px;
        }
        .EditScheduledModal__addDescription {
            margin: 23px 15px;
        }
    }
    .EditScheduledModal-collapsibleSection {
        margin: 0 30px;
        .Input {
            margin: 20px 30px 30px 0;
        }
        .EditScheduledModalWorkflowInputs--emptyLabel {
            margin-bottom: 30px;
        }
    }
    hr {
        margin: 30px 20px;
    }
    .EditScheduledModal-editCurrent {
        .EditScheduledModal-editCurrentOptions {
            display: flex;
            align-items: center;
            margin: 30px 20px;
            .pauseTriggerButton {
                margin-right: 20px;
            }
            .deleteTriggerButton {
                @extend %bold14;
                color: $neutral-50;
            }
        }
        .updateTriggerButton {
            position: absolute;
            bottom: 30px;
            right: 30px;
        }
    }
}
