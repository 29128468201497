@import '../../../styles/partials/index';

.Report {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .DashboardTab {
        margin: 10px 30px 30px;

        @include phone {
            margin: 10px 25px;
        }
    }

    // always center model bars on Report page    
    .DashboardHeader__modelBars {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);  // Only move horizontally, not vertically
        display: flex;
        justify-content: center;
        align-items: center;
    }
        
    .Report__page {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        .DashboardHeader {
            background: linear-gradient(180deg, $white 0%, $neutral-98 100%);
            opacity: 0.97;
            border-radius: 0 0 10px 10px;
            box-shadow: 0px 0px 4px rgba(33, 48, 77, 0.1);
            padding-bottom: 20px;
            height: auto;
            position: absolute;
            width: 100%;
            z-index: 20;
            .DashboardHeader__flex {
                flex-flow: nowrap;
                .ModelBar {
                    margin-left: 10px;
                }
                .FilterBar {
                    margin-right: 10px;
                }
            }

            // leaving this as a fun friday to try  and get working
            // .ModelBar,
            // .FilterBar {
            //     animation-name: bar-animate-down;
            //     animation-duration: 1s;
            //     @keyframes bar-animate-down {
            //         0% {
            //             // display: block;
            //             opacity: 0;
            //             transform: translateY(-20px);
            //         }

            //         100% {
            //             opacity: 1;
            //             transform: translateY(0px);
            //         }
            //     }
            // }
        }
        .DashboardHeader--modelPanelOpen {
            .DashboardHeader__flex,
            .DashboardHeader__titleRow {
                margin-right: 1px;
            }
        }
    }

    .Report__body {
        display: flex;
        justify-content: center;
        margin: 150px 30px 0 300px;
        width: calc(100vw - 300px); // Always 300px less than the viewport width

        @media (max-width: 600px) {
            width: 100%; // Full width for very small screens
            margin: 150px 0 0 0; // Adjust margin if needed for smaller screens
        }        

        .Report__cards {
            width: 100%;
            max-width: 80%;
            height: 500px;
            padding-right:18%;

            .DashboardTab {
                margin: 0 30px 30px;
            }

            .DashboardEmpty {
                margin-left: -270px;
            }
        }
    }

    .ModelPanel {
        margin-top: -10px;
        padding-top: 30px;
        height: calc(100% + 10px);
        z-index: 99;
    }
    .AddPlaceholder {
        height: 280px !important;
        background-color: $white;
        border: 1px solid $neutral-90;
        @extend %boxShadowLight;
    }
    .SectionList {
        position: absolute;
        left: 30px;
        width: 240px;
        top: 150px;
    }
}
