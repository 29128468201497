@import '../../../../styles/partials/index';

.FilterBar {

    @extend %regular14;
    display: flex;
    align-items: center;
    overflow: hidden;

    width: fit-content;
    max-width: 100%;
    height: 33px;


    background-color: $white;
    border: 1px solid $neutral-90;
    border-radius: 10px;

    >.Icon {
        @include svgColor($neutral-85);
        margin: 6.5px 8px;
    }

    .Filter__Value--icon {
        padding-top: 3px;
    }
}

.FilterBar__Array {
    @extend %horizontalScrollbar__small;
    display: flex;
    align-items: center;
    overflow: overlay;

    .IconButton {
        margin: 0 3px;
    }
}

// only needed for vertical filter
.FilterBar__clear {
    display: flex;
    flex-direction: row;
    align-items: center;

    .IconButton {
        height: 33px;
        width: 33px;
        border-radius: 9px;
        border: 3px solid $white;
    }
}

.FilterBar__clearText {
    @extend %semibold14;
    margin-left: 5px;
    margin-right: 12px;
    color: $neutral-70;
}

.FilterBar--loading {
    min-width: 100px;
}
