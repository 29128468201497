@import '../../../styles/partials/index';

.ModelOnboardWorkflow {
    display: flex;
    height: calc(100% - 110px); // 110px for header

    .RunModel {
        // makes this tab extend up to the header
        height: calc(100% + 16px);
        margin-top: -16px;
    }
}

.ModelOnboard__topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
