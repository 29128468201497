@import '../../../../styles/partials/index';

.TransferProjectAssets {
    width: 1100px;
}

.TransferProjectAssets__container {
    margin: 20px;
}

.TransferProjectAssets__notification {
    @extend %regular14;
    max-width: 550px;
    text-align: center;
    margin: auto;
}

.TransferProjectAssets__projects {
    display: flex;
    flex-direction: row;

    > * {
        width: 230px;
        margin-right: 30px;
    }

    margin-top: 30px;
    margin-bottom: 30px;
}


.TransferProjectAssets__assets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}