@import '../../../styles/partials/index';

.PublicDashboard__main {
    height: calc(100% - 40px);
}

.PublicDashboard__header {
    height: 40px;
    display: flex;
    align-items: center;
    background: $white;
    position: relative;
    z-index: 41;
    box-shadow: 0px 0px 4px rgba($neutral-20, 0.2);
    border-radius: 0px 0px 10px 10px;

    @include phone {
        .Icon {
            display: none;
        }
    }

    .Icon {
        @include svgColor($blue-300);
        margin-left: 30px;
    }
}

.PublicDashboard__shared {
    @extend %bold14;
    flex: 1;
    margin-left: 10px;

    @include phone {
        margin-left: 20px;
    }
}

.PublicDashboard__power {
    margin-right: 30px;
    color: $neutral-60;

    a {
        font-weight: bold;
    }

    @include phone {
        margin-right: 20px;
    }
}
