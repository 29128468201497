@import '../../../styles/partials/index';

.PackageDisplay {
    flex-direction: column;
    color: $neutral-40;

    .DetailHeader__header {
        border-bottom: 1px solid $neutral-90;
    }

    hr {
        margin: 0;
    }

    .Button {
        margin: 0px 0;
    }
}

.PackageDisplay__state {
    @extend %bold18;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    > .Icon {
        @include svgColor($blue-300);
    }

    > span {
        margin-top: 10px;
    }
}

.PackageDisplay__state--building > .Icon {
    animation: rotate 2s linear 0s infinite;
}

.PackageDisplay__content {
    display: flex;
    flex-direction: row;
    padding: 30px 30px 0px 30px;
    height: 800px;

    .CodeEditor {
        flex-grow: 2;
        min-height: 180px;
        max-height: 800px;
        min-width: 400px;

        .CodeEditor__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: -30px;
            margin-top: -12px;
            max-width: calc(100% - 10px);
        }

        .CodeEditor__codeBox {
            margin-top: 40px;
        }
    }
}

.PackageDisplay__items {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    min-width: 280px;
    margin-right: 10px;
    padding: 18px 0px 0px 0px;
}

.PackageDisplay__items__column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.PackageDisplay__column1 .PackageDisplay__input {
    margin-right: 30px;
}

.PackageDisplay__column2 {
    flex: 2;
}

.PackageDisplay__items__row {
    display: flex;
    padding-top: 15px;

    .Input:first-child {
        margin-right: 30px;
    }
}

.PackageDisplay__input {
    margin-bottom: 10px;
}

.Input__label {
    font-size: 15px;
    padding-bottom: 3px;
}

.PackageDisplay__extra {
    display: flex;
    flex-direction: column;
    flex-grow: 1.5;

    .PackageDetails__button_edit_dependencies {
        height: 10px;
    }

    .PackageDisplay__dependencies {
        display: flex;
        flex-direction: row;
        flex-grow: 3;
        max-height:50%;
    }

    .PackageDisplay__environments {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        max-height:50%;
    }
}

.PackageDisplay__extra__title {
    @extend %bold18;
    margin: 45px 0 10px;
}

.PackageDisplay__sourceCode {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 200px;
}

.PackageDisplay__models {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.PackageDisplay__models-item {
    width: 33.3%;
    margin-bottom: 15px;
}

.PackageDisplay__models-name {
    @extend %semibold14;
    margin-bottom: 15px;
}

.PackageDisplay__models-name {
    @extend %semibold14;
    margin-bottom: 15px;
}

// MAX HEIGHT FOR CODE EDITOR
