@import './_colors';

%horizontalScrollbar__small::-webkit-scrollbar {
    height: 3px;
}
%horizontalScrollbar__small::-webkit-scrollbar-track {
    background: $neutral-95;
    border-radius: 4px;
}
%horizontalScrollbar__small::-webkit-scrollbar-thumb {
    background: $neutral-80;
    border-radius: 4px;
}
