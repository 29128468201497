@import '../../../../styles/partials/index';

.FormElementMapping {
    margin: 30px 0;
    padding: 30px;
}

.FormTitle {
    .Input {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.FormTitle__titleInput {
    @extend %bold24;
}

.FormQuestion {
    hr {
        margin: 30px 0;
    }
}

.FormQuestion__header {
    margin-top: -10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .IconButton {
        margin-left: 20px;
    }
}

.FormQuestion__question {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}

.FormQuestion__questionInput {
    @extend %bold18;
    flex: 1;
    margin-right: 30px;
}

.FormQuestion__description {
    margin-top: 30px;
}

.FormQuestion__addDescription {
    margin-top: 10px;
}

.FormQuestion__configInputs {
    display: flex;

    .Input {
        width: 180px;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
}
