@import '../../../../styles/partials/index';

.DatasetHistory {
    height: 100%;

    hr {
        margin: 30px 0;
    }

    .Input {
        margin-right: 30px;
    }

    .Table__row {
        td:first-child {
            word-break: break-all;
            overflow: auto;
        }
        .IconButton {
            opacity: 0;
        }

        &:hover .IconButton,
        .IconButton--active {
            opacity: 1;
        }
    }
}

.DatasetHistory__info {
    display: flex;
}
