@import '../../../../../../../styles/partials/index';

.OrganizationLogo {
    display: flex;
    flex-direction: column;
    @extend %semibold14;
    color: $neutral-40;

    .Logo {
        cursor: pointer;
        margin-top: 2px;
        border: 1px solid $neutral-90;
        transition: ease-in-out 0.1s;

        &:hover {
            border: 3px solid $neutral-90;
        }
    }
}

.OrganizationLogo__uploadModal {
    width: 350px;
}

.OrganizationLogo__uploadModal__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .Button {
        margin-left: 10px;
    }
}
