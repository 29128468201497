
@import '../../styles/partials/index';

.Packages__main {
    height: calc(100% - 40px); // 40px for top & bottom margin of page

    > .Card {
        height: 100%;
        padding: 0;
        margin: 20px 30px;
    }
}
