@import '../../../../styles/partials/index';

.DatasetMapping {
    box-shadow: 0 0 6px rgba($neutral-20, 0.2);
    border-radius: 8px;
    background-color: $white;
    height: 200px;
}

.DatasetMapping__header {    
    border-bottom: 1px solid $neutral-90;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
