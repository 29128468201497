@import '../../../../../styles/partials/index.scss';

.LayoutButton {
    width: 407px;
    height: 261px;
    margin: 18px 0 0 0;
    .Card {
        background-color: $neutral-98;
        @extend %bold18;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: unset;
    }
    :hover {
        cursor: pointer;
        background-color: $neutral-95;
        transition: ease-in-out 0.1s;
    }
}
.LayoutButton--selected {
    .Card {
        box-shadow: inset 0 0 0 2px $blue-200;
    }
}
