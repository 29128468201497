.ModelOutputs {
    height: 100%;

    hr {
        margin: 10px 30px 30px;
    }
}

.ModelOutputs__header {
    display: flex;
    align-items: center;
    margin: 30px;

    .SelectableItem {
        margin-right: 10px;
        max-width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .Input {
        margin-left: auto;
    }
}

.ModelOutputs__main {
    margin: 30px;
    height: calc(100% - 157px); // 97px for selectable items, 60px for margin

    .DataTable {
        max-height: calc(100% - 80px); // 80px for input
    }
}

.ModelOutputs__datasetInputs {
    margin-bottom: 20px;
    display: flex;
}

.ModelOutputs__datasetName {
    width: 370px;
    margin-right: 10px;
}

.ModelOutputs__datasetDescription {
    flex-grow: 1;
}
