@import '../../../../styles/partials/index';

.AddFilterButton {
    @include svgColor($neutral-70);
    position: relative;
    align-items: center;

    color: $neutral-70;
    background-color: $white;
    cursor: pointer;

    .IconButton {
        height: 33px;
        width: 33px;
        border-radius: 9px;
        border: 3px solid $white;
    }
}

.AddFilterButton__textButton {
    @extend %shortTransition;
    @extend %semibold14;

    position: relative;

    display: flex;
    align-items: center;

    height: 33px;
    padding: 3px 15px;

    border-radius: 9px;

    outline: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.AddFilterButton__textButton--menuOpen {
        background-color: $neutral-95;
        margin-left: -1px;
        padding-left: 16px;
    }

    &:hover,
    &:focus {
        box-shadow: inset 0 0 0 3px $neutral-90;
    }

    &:active,
    &.AddFilterButton__textButton--menuOpen {
        box-shadow: inset 0 0 10px rgba($neutral-70, 0.4), inset 0 0 0 3px $neutral-90;
        transition: none;
    }

    .Icon {
        margin: 0 0 0 8px;
    }
}

.AddFilterButton__iconButtonFilter--dark {
    @include svgColor($white);
    padding: 0;
    background-color: transparent;
    color: $white;

    .AddFilterButton__textButton {
        padding: 8px;
        margin: -1px;
        height: inherit;
        border-radius: 5px;

        &:hover,
        &:focus,
        &:active {
            background-color: $neutral-50;
            box-shadow: inset 0 0 0 3px $neutral-80;
            z-index: auto;
        }

        &:active {
            box-shadow: inset 0 0 12px rgba($white, 0.4), inset 0 0 0 3px $neutral-80;
            transition: none;
        }
    }

    .AddFilterButton__textButton--menuOpen {
        background-color: $neutral-50;

        &:active {
            box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 3px $neutral-80;
            transition: none;
        }
    }

    .Icon {
        margin: 0;
    }

    span {
        padding-left: 6px;
    }
}
