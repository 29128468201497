@import '../../../../../../styles/partials/index';

.SelectUsers {
    flex: 1;
    position: relative;
    margin-bottom: 30px;
}

.SelectUsers__contextMenu {
    width: 650px;

    .ContextMenu__subMenu:nth-child(2) {
        max-height: 320px;
        overflow: auto;
    }
}

.SelectUsers__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

.SelectUsers__orgSelect {
    @extend %semibold14;
    display: flex;
    align-items: center;

    .Input {
        margin-left: 5px;
    }
}

.SelectUsers__userItem {
    @extend %regular14;
    color: $neutral-90;
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
        background-color: $neutral-40;
    }
}

.SelectUsers__userItem__col {
    display: flex;
    align-items: center;

    .UserIcon,
    .UserIcon--logo {
        margin: 0 10px;
    }

    &:first-child {
        @extend %bold14;
        color: $white;
    }
}

.SelectUsers__noUsers {
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
