@import '../../../../styles/partials/index';

.RunInfoModal {
    width: 500px;
    min-height: 175px;
    max-height: 750px;
    overflow: auto;
}

.RunInfoModal__requestor {
    @extend %regular12;
    color: $neutral-40;
    margin-bottom: 10px;
}

.RunInfoTimeline__item {
    display: flex;
    overflow: hidden; // prevents 2 scrollbars (not sure why this happens)

    .Icon {
        margin-right: 5px;
    }

    &:not(:first-child) {
        margin-top: 15px;
    }
}

.RunInfoTimeline__item--failed {
    @include svgColor($red-400);
}

.RunInfoTimeline__text {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 25px); // icon size
}

.RunInfoTimeline__itemTitle {
    @extend %bold14;
}

.RunInfoTimeline__failedMessage {
    margin-top: 10px;
}

.RunInfoInputs__title {
    @extend %bold14;

    &:not(:first-child) {
        margin-top: 15px;
    }
}

.RunInfoInputs__input {
    @extend %regular14;
    color: $neutral-40;
    margin-left: 18px;
}

.RunInfoInputs__inputValue {
    @extend %bold14;
}
