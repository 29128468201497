@import '../../../../styles/partials/index';

.FilterSummary {
    .KPI {
        .KPI__value {
            @extend %regular14;
        }

        .KPI__label {
            @extend %bold14;
            width: auto;
            margin-right: 5px;

            &::after {
                content: ': ';
            }
        }
    }
}
