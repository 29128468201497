@import '../../../../styles/partials/index';

.FilterMenu {
    width: 350px;
}

.FilterMenu__maxHeight {
    max-height: 700px;
    overflow: auto;
    padding-bottom: 1px;
}

.FilterMenu__empty {
    text-align: center;
    padding: 15px;
}

.FilterMenuDataset {
    max-height: 500px;
    overflow: auto;
    background: $neutral-40;

    .ListItem {
        padding-left: 20px;
    }

    > .ListItem:first-child {
        // the dataset list item
        @extend %bold14;
        padding-left: 10px;
    }
}
