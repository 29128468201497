@import '../../../../../../styles/partials/index';

.OrganizationsProjectList {
    background-color: $white;
    padding: 0 35px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.OrganizationsProjectList__header {
    width: 100%;
    display: inline-flex;
    margin: 30px 0;

    .OrganizationsProjectList__search {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
    }
}

.OrganizationsProjectList__title {
    @extend %bold24;
}

.OrganizationsProjectList__body {
    max-width: 830px;
    margin: 0 auto;

    > * {
        margin-bottom: 15px;
    }

    .SelectableBar {
        background-color: $white;
        border: 1px solid $neutral-90;
        cursor: unset;
    }
}

.OrganizationsProjectList__modal {
    min-width: 450px;
}

.OrganizationsProjectList__empty {
    text-align: center;
    margin: 0 auto;

    .Icon {
        margin-bottom: 10px;
    }

    .OrganizationsProjectList__empty--Title {
        @extend %bold18;
        margin-bottom: 50px;
    }
}
