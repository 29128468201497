@import '../../../../styles/partials/index';

.SerumOperationButton__binInput {
    padding: 5px;
}

.SerumOperationButton__menu {
    max-height: 300px;
    width: 140px;

    .ContextMenu__subMenu {
        max-height: 298px;
        overflow: auto;
    }
}
