@import '../../../../../../styles/partials/index';

.DataMenu,
.CollectionsMenu {
    max-height: 264px;
    width: 275px;

    .ContextMenu__subMenu {
        max-height: 220px; // height of datamenu - height of header (264 - 43px - 1px for border)
        overflow: auto;
    }

    .ContextMenuItem__name {
        max-width: 275px; // overwrites default max width of 150px, stopping unneccesary truncation
    }
}

.DataMenu__header {
    @extend %semibold14;
    display: flex;
    align-items: center;
    padding: 5px;
}

.DataMenu__searchButton {
    margin-left: auto;
}
