@import '../../../../styles/partials/index';

.SectionList {
    display: flex;
    flex-direction: column;
    width: 240px;

    .IconButton {
        float: right;
    }

    .SectionListItem:first-child {
        .SectionListItem__lines {
            .SectionListItem__line:first-child {
                border-right: solid 0px;
            }
        }
    }

    .SectionListItem:last-of-type {
        .SectionListItem__body {
            .SectionListItem__lines {
                .SectionListItem__line:last-child {
                    border-right: solid 0px;
                }
            }
        }
    }
}

.SectionList__addSection {
    margin-left: 5px;
    margin-top: 5px;
}

.AddSectionListModal {
    width: 340px;
    .AddSectionListModal__form {
        margin-bottom: 15px;
    }
}

.SectionList__Menu {
    .Icon {
        @include svgColor($white);
    }
}
