.custom-main {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  padding: 0 5rem;
  overflow: scroll;
  border-radius: 10px;
  background-color: #f9f9fc; /* light grey color */


}


.custom-main .custom-send-btn {
  background-color: #64C3F2
}

.custom-main .custom-clear-btn {
  background-color: #f9ca8e
}



/* Dark mode styling */
body[data-theme='dark'] .custom-main {
  background-color: #333; /* light-grey in dark mode */
}

.custom-tabs {
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  background-color: #f9f9fc; /* light-grey in dark mode */
}

.custom-tab {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  transition: border-color 0.3s;
}

.custom-tab:hover {
  border-color: #ccc;
}

.custom-tab-active {
  border-color: #007bff; /* or any other color you'd like for active state */
}

.custom-messages-container {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  background-color: #f9f9fc;
  border-radius: 15px;
}

.custom-template-container {
  display: flex;
  margin: 1rem 0;
}

.custom-template-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin: 0 auto;
  list-style: none;
}

.custom-template-item {
  padding: 1.5rem;
  border: 1px solid #bbb; /* slate-300 color */
  border-radius: 0.5rem;
  cursor: pointer;
  transition: border-color 0.3s;
}

.custom-template-item:hover {
  border-color: #666; /* slate-500 color */
}

.custom-template-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.custom-textarea-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
}

.custom-textarea {
  width: 100%;
  min-height: 3rem;
  max-height: 20rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  font-size: 1.3rem;
  resize: vertical;
  background-color: #f9f9fc; /* light grey color */
  border-radius: 0.5rem;
}

.custom-send-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #64C3F2; /* or any color of your choice */
  border: none;
  color: white;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.custom-send-btn:hover {
  background-color: #3b9bde; /* darken color on hover */
  margin-left: 10px;

}

.custom-send-btn:disabled {
  background-color: #bfc6dc; /* grey color for disabled button */
  cursor: not-allowed;
  margin-left: 10px;

}


.custom-clear-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #4eb6ef; /* or any color of your choice */
  border: none;
  color: white;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.custom-clear-btn:hover {
  background-color: #F9B366; /* darken color on hover */
  margin-left: 10px;
}



.custom-form {
  display: flex;
  background-color: #f5f5f5; /* light grey color */
  flex-direction: column;
  padding: 0 2rem;
  margin-bottom: 0.5rem;
}

/* Responsive styles */
@media (min-width: 640px) {
  .custom-form {
    flex-direction: row;
    gap: 0.5rem;
    background-color: #f9f9fc; /* light grey color */
  }

  .custom-select {
    width: 10rem;
    background-color: #f9f9fc; /* light grey color */
    justify-content: center;
    align-items: center;
    display: flex;
    height: 4rem;
    margin-top: 10px
  }
}

@media (min-width: 768px) {
  .custom-messages-container {
    padding: 1rem 4rem;
  }

  .custom-form {
    padding: 1rem 4rem;
    background-color: #f9f9fc; /* light grey color */
  }
}
