@import '../../../../../styles/partials/index';

.GroupByBucket {
    display: flex;
    flex-flow: column;
}

.GroupByBucket__operation {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $neutral-90;
    margin: 0 -10px;
    padding: 8px 0;

    .Chip {
        margin: 0 3px;
    }
}

.GroupByBucket__groups {
    margin: 0 -10px;
    padding: 0 10px;
    overflow: auto;
}

.GroupByBucket__color {
    @extend %regular14;
    display: flex;
    align-items: center;
    margin: 7px 0;

    .Chip {
        margin-right: 7px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.ChartMapping__AxisConfig {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    .Chip {
        margin: 0 3px;
    }
}

.ascendingIcon--y {
    transform: rotate(270deg);
}
.descendingIcon--y {
    transform: rotate(270deg);
}
