@import '../../../../styles/partials/index';

.ProjectMenu {
    width: 255px;

    .ContextMenuItem__name {
        overflow: unset;
    }

    .ContextMenuItem {
        align-items: unset;
    }
}

.ProjectMenu__item {
    padding: 9px;
    display: flex;
    flex-direction: column;
}

.ProjectMenu__role {
    @extend %bold14;
    padding: 7px;
}

.ProjectMenu__roleDescriptionsTitle {
    @extend %semibold14;
}

.ProjectMenu__roleDescription {
    @extend %regular12;
    font-style: italic;
    color: $neutral-95;
    margin-top: 5px;
}

.ProjectMenu__members {
    @extend %bold14;
    padding: 7px;
    overflow: hidden;
}

.ProjectMenu__memberIcons {
    display: flex;
    margin-top: 8px;
    overflow-x: auto;

    > * + * {
        margin-left: 5px;
    }
}

.LeaveProject__modal {
    max-width: 452px;
    .Notification {
        margin-bottom: 10px;
    }
}

.ProjectMenu__public {
    width: 100%;
    padding: 7px;

    .Input__label {
        flex: 1;
    }
}

.ProjectMenu__submessage {
    color: $neutral-95;
    font-style: italic;
    margin-top: 5px;
}
