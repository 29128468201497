@import '../../../../../styles/partials/index';

.ChartHeader {
    border: 1px solid $neutral-90;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $white;

    > main {
        flex: 1;
        height: fit-content;
        max-height: calc(100% - 30px); // 100% minus header height
        position: relative;
    }
}

.ChartHeader__header {
    flex: 0 0 30px;
    background-color: $white;
    border-radius: 6px;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;

    .ButtonList {
        padding: 0;
        background-color: $white;
        margin-right: 10px;

        .ToolTip {
            margin-right: 20px;
        }
    }
}

.ChartHeader__controls {
    justify-self: flex-end;
    margin-left: auto;

    .IconButton {
        padding-left: 20px;
        margin-right: 70px;
    }
    .IconButton__label {
        // padding: 5px 5px; /* Increase the padding around the text */
        min-width: 60px;  /* Ensure the label has a minimum width */
        display: inline-block; /* Ensure padding and width are respected */
        text-align: center;  /* Center the text within the label */
    }    
}

.ChartHeader__chartChangeWarningModal {
    width: 340px;
}
