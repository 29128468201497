@import '../../../../../styles/partials/index';

.RoleDetails__Modal {
    margin: 0 10px;
    max-width: 993px;

    .TextInput {
        margin-bottom: 30px;
        max-width: 288px;
        height: 40px;
    }
}
