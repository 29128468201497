@import '../../../styles/partials/index';

.FormsList {
    margin: 30px;
}

.FormsList__title {
    @extend %bold24;
}

.FormsList__cards {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .FormCard {
        margin: 20px 20px 0 0;
    }
}

.FormsList__empty {
    text-align: center;
    max-width: 300px;
    margin: 200px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Icon {
        margin-bottom: 10px;
    }
    .FormsList__empty--Title {
        @extend %bold18;
        margin-bottom: 4px;
    }
    .FormsList__empty--Text {
        @extend %regular14;
        margin-bottom: 15px;
    }
}

.CreateFormModal {
    width: 500px;

    > * {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
