@import '../../../../../styles/partials/index.scss';

.EditMembers__Modal {
    padding: 0 19px 0 34px;
    width: 1100px;
    max-width: 100%;
    min-height: 550px;
    max-height: 95vh;
    display: flex;
    flex-direction: column;
}

.EditMembers__SearchBar {
    width: 300px;
}

.EditMembers__Header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    padding: 0 15px 20px 0;
}

.EditMembers__Body {
    flex: 1 0 0;
    max-height: 70vh;

    .SelectableBar {
        margin-bottom: 10px;
    }

    .scrollbar-container {
        padding-right: 15px;
    }
}

.EditMembers__empty {
    text-align: center;
    max-width: 250px;
    margin: 10px auto;

    .EditMembers__empty--Title {
        @extend %bold18;
    }
}

.EditMembers__ModalOverlay__main {
    display: flex;
    padding: 10px 10px;
}

.EditMembers__ModalOverlay .PermissionTable {
    margin: 0 10px;
}

.EditMembers__selectRoleInfo {
    margin-left: 30px;
    margin-right: 5px;
    width: 250px;
}

.Input.EditMembers__selectRole {
    margin-bottom: 10px;
}

.EditMembers__roleInfo {
    font-style: italic;
    color: $neutral-60;
    margin-bottom: 30px;
}

.EditMembers__permissionButton {
    margin-top: 28px;
}

.EditMembers__ModalCard {
    hr {
        margin: 0;
        color: $neutral-95;
        background-color: $neutral-95;
    }
}
