@import '../../../../styles/partials/index';

.PermissionTable {
    .PermissionTable__header {
        @include svgColor($neutral-40);
        background-color: $neutral-95;
        display: inline-flex;
        border-radius: 10px;
        justify-content: space-evenly;
        width: 100%;

        .columnNames {
            margin: 10px 0 10px 30px;
            align-items: center;
            display: inline-flex;
            flex: 1;
        }

        .text {
            @extend %bold14;
            color: $neutral-20;
        }

        .Icon {
            margin-right: 10px;
        }
    }

    .PermissionTable__options {
        display: inline-flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 10px 0 0 0;
    }

    .PermissionTable__checkBox {
        flex: 1;
        margin: 0 0 0 30px;
    }

    .PermissionTable__radio {
        flex: 1;
        margin: 0 0 0 30px;
    }

    .Input,
    .RadioOption {
        margin-bottom: 10px;
        margin-top: 0;
    }

    .Input__label,
    .RadioOption__label {
        @extend %semibold14;
        color: $neutral-40;
    }
}
.PermissionTable--disabled {
    .PermissionTable__options {
        opacity: 0.6;
    }
}
