@import '../../../../styles/partials/index';

.ModelBar {
    display: inline-flex;
    align-items: center;
    height: 35px;
    max-width: 100%;

    position: relative;
    left: 0;

    padding: 5px 5px 5px 8px;

    background: $white;
    border: 1px solid $neutral-90;
    border-radius: 10px;

    transition: all 0.25s ease;

    .VerticalLine {
        margin: 0 10px 0 8px;
    }

    > .ToolTip {
        .Icon {
            @include svgColor($neutral-85);
        }
    }
}

.ModelBar--disabled {
    @include svgColor($neutral-80);
}

.ModelBar--hidden {
    // a delay and a slower transition when you hide the model bar (no delay when you show)
    transition: all 0.5s ease-out 0.2s;
    // move to the right and fade
    left: 100%;
    opacity: 0;
}

.ModelBar--showModelPanelButton {
    .Button {
        margin-right: 18px;
    }

    .SidePanelButton {
        // this button is positioned to be on top of the border of the model bar
        position: absolute;
        right: -10px;
    }
}

.ModelBar__inputs {
    display: flex;
    flex: 1;
    align-items: center;
    // overflow: auto;

    > .Input,
    > .ConstraintInput {
        margin-right: 10px;
        flex-shrink: 0;
    }

    .TextInput {
        max-width: 300px;
    }

    .NumberInput {
        max-width: 200px;
        width: auto;
    }

    .MultiselectInput__nuggets {
        // I'd prefer just .MultiSelectInput { max-width: 300px }, which works in storybook but not in deepsea??
        max-width: max(200px, 15vw);
    }
}
