@import '../../../../styles/partials/index';

.OrganizationSettings {
    display: flex;
    flex-direction: column;
    height: 100%;

    .Tabs {
        height: auto;

        > main {
            overflow: auto;
        }
    }
}
