@import '../../../../styles/partials/index';

.UnitTypeSelection {
    @extend %align-col__center;
    width: inherit;
    height: calc(100% - 20px);
    text-align: center;
    padding: 10px;

    .UnitTypeSelection__title {
        @extend %bold18;
        color: $neutral-40;
    }

    .UnitTypeSelection__subtitle {
        @extend %regular14;
        margin-top: 5px;
        color: $neutral-40;
    }
}

.UnitTypeSelection__type__row {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 350px;
    justify-content: space-around;
    align-items: center;
}

.UnitTypeSelection__type {
    @extend %align-col__center;
    color: $neutral-70;

    &:hover,
    &:active {
        @extend %shortTransition;
        @include svgColor($blue-400);
        color: $blue-400;

        .UnitTypeSelection-type-circle {
            background: $neutral-95;
            border: 1px solid $blue-400;
        }
    }
}

.UnitTypeSelection__type__circle {
    @extend %align-col__center;
    outline: none;
    border: 1px solid $neutral-90;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    cursor: pointer;

    &:hover,
    &:active {
        @extend %shortTransition;
        @include svgColor($blue-400);
        background: $neutral-95;
        border: 1px solid $blue-400;
    }
}

.UnitTypeSelection__type__title {
    @extend %bold14;
    color: $neutral-60;
    user-select: none;
    margin-top: 10px;
}
