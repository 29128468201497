@import '../../../../styles/partials/index';

.CategoryFilter {
    .ListInput__header {
        padding: 15px 20px 0;
    }

    .ListInput__List {
        border-radius: 0;
        max-height: 310px;
    }

    .ListItem {
        background-color: $neutral-40;
    }
    .categoryTextInput {
        margin: 0 15px 15px;
    }
}

.TextFilter {
    > main {
        padding: 15px 20px;
    }
}

.DateFilter {
    > main {
        padding: 15px 20px;
    }
}

.NumberFilter {
    > main {
        padding: 15px 20px;
    }
}

.TimeFilter {
    > main {
        padding: 15px 20px;
    }
}

.NumberFilter__main--range,
.DateFilter__main--between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DateFilter__seperator,
.NumberFilter__seperator {
    margin: 5px;
}

.FilterHeader {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    background-color: $neutral-40;
    border-top: 1px solid $neutral-50;

    .Input {
        width: 130px;

        /* stylelint-disable-next-line */
        #option > div {
            min-width: 0; // select input emotion.js ;'(
        }
    }
}

.FilterHeader__name {
    @extend %bold14;
    flex: 1;
    margin-left: 5px;
    margin-right: 10px;
    max-width: 150px;
    overflow-wrap: break-word;
}
