@import '../../../../styles/partials/index';

.Placeholder {
    height: 100%;
    width: 100%;
}

.Placeholder__content {
    @extend %animationFadeIn;
    @extend %regular12;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Placeholder__icon {
    @include svgColor($neutral-80);
    color: $neutral-80;

    i::before,
    img::before {
        padding-top: 0;
    }
}

.Placeholder__icon--error {
    @include svgColor($red-400);
    color: $red-400;
}

.Placeholder__message {
    @extend %regular14;
    color: $neutral-40;
    margin-top: 0;
    padding: 0 30px;

    header {
        @extend %bold18;
        text-align: center;
        color: $neutral-40;
        margin-bottom: 5px;
    }

    div {
        width: 250px;
        text-align: center;
    }
}
