@import '../../../../styles/partials/index';

.PackageFunctionSelect {
    display: flex;
    align-items: center;

    .Input {
        width: 30%;
    }
}

.PackageFunctionSelect__slash {
    @extend %regular14;
    color: $neutral-80;
    margin: 30px 20px 10px;
}
