@import '../../styles/partials/index';
// @import 'daisyui/dist/full.css';

/* CSS styles */
.ChatBox {
  /* Add your app styles here */
}

.modal {
  display: none;
  /* Add modal styles here */
}

.modal.open {
  display: block;
}

.modal-content {
  /* Add modal content styles here */
}

.flex-container {
  display: flex;
  /* Add flex container styles here */
}
