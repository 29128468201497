
section {
    width: 16rem; /* default to closed sidebar */
    background-color: var(--neutral-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: 100vh;
    padding-top: 1rem;
    position: relative;
    transition: width 0.1s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Sidebar Open State */
.sidebar-open {
    width: 18rem;
}

/* Header Styles */
header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
    margin: 0 auto;
}

.logo {
    width: 1rem;
    height: 1rem;
}

/* Hide elements by default */
.hidden {
    display: none;
    transform: scale(0);
}

/* Button Styles */
.btn {
    margin: 0 auto;
}

.btn-square {
    /* Your btn-square styles here */
}

.btn-ghost {
    background: none;
    border: none;
}

/* Menu Styles */
.menu {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-item {
    border: 1px solid slategray;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
}

.bottom-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
    gap: 0.5rem;
}
