@import '../../../../styles/partials/index';

.KpiMapping {
    display: flex;
    height: 100%;
}

.KpiMapping__main {
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .MappingBucket {
        flex: 0 0 92px;
        margin-top: 15px;
    }
}

.KpiMapping__settings {
    position: relative;
    box-shadow: 0 4px 6px rgba($neutral-20, 0.2);
    margin: -25px -15px -215px 0;
    z-index: 1;
}

.KpiMapping--settingsOpen {
    .KpiMapping__main {
        margin-right: 15px;
    }
}

.KpiMapping__preview {
    border: 1px solid $neutral-90;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $white;

    > main {
        flex: 1;
        height: fit-content;
        max-height: calc(100% - 30px); // 100% minus header height
        position: relative;
        border-top: 1px solid $neutral-90;
    }

    > header {
        flex: 0 0 30px;
        background-color: $white;
        border-radius: 6px;
        height: 40px;
        min-height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
    }
}
