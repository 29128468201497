@import '../../../../styles/partials/index';

.Validations {
    display: flex;
    flex-direction: column;
    width: max-width;
    max-width: 100%;

    padding: 0;
    list-style-type: none;
}

.Validations__item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.Validations__bullet {
    @include svgColor($neutral-80);
    color: $neutral-80;
}

.Validations__bullet--success {
    @include svgColor($blue-500);
    color: $blue-500;
}

.Validations__label {
    margin-left: 10px;
}
