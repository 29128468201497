@import './_colors';
@import './_shapes';

%messagePane {
    padding: 10px 17px 10px 10px;
    border: 1px solid $neutral-80;
    border-radius: 4px;
    background-color: $neutral-98;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .Icon {
        @include svgColor($blue-500);
        margin: 0 15px 0 10px;
        color: $blue-500;
    }

    > p {
        flex: 1 1 calc(100% - 70px);
        margin: 10px 0;
    }
}

%frame {
    position: relative;
    padding: 10px;

    border-radius: $border-radius;
    border: 1px solid $neutral-80;
    background-color: $neutral-98;
}
