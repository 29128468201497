@import '../../../../styles/partials/';
.CreateScheduledModal {
    width: 1380px;

    .CreateScheduledModalWorkflow {
        height: 600px;
    }

    .CreateScheduledModalWorkflowInputs {
        display: flex;

        .Input {
            width: 300px;
            margin: 0 20px;
        }

        .CreateScheduledModal__addDescription {
            margin: 23px 15px;
        }
    }

    .CreateScheduledModal-collapsibleSection {
        margin: 0 30px;

        .Input {
            margin: 20px 30px 30px 0;
        }

        .CreateScheduledModalWorkflowInputs--emptyLabel {
            margin-bottom: 30px;
        }
    }

    hr {
        margin: 30px 20px;
    }
}
