@import '../../../styles/partials/index';

.SelectableItem {
    @extend %regular12;
    display: flex;
    align-items: center;

    padding: 10px;
    background-color: $neutral-98;
    border-radius: 8px;

    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
        background-color: $neutral-95;
    }
}

.SelectableItem__name {
    @extend %bold14;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $neutral-40;
    flex: 1;
    align-items: center;

    .SelectableItem__subName {
        @extend %regular12;
        margin-left: 4px;
        padding-top: 1px;
        display: flex;
        align-items: center;
    }

    .SelectableItem__flag {
        @extend %regular12;
        color: $amber-800;
        display: flex;
        margin-left: 4px;
    }
}
.SelectableItem__endingSoon {
    @extend %regular12;
    font-style: italic;
    color: $neutral-60;
    display: flex;
    align-items: center;
    .Icon {
        @include svgColor($amber-600);
        margin-right: 5px;
    }
}

.SelectableItem__info {
    color: $neutral-40;
    margin-top: 5px;
}

.SelectableItem__type {
    @include svgColor($neutral-85);
    color: $neutral-85;
    margin-right: 8px;
    line-height: 0; // this fixes a style bug where an <Icon size="16px" /> causes this (div.SelecteableItem__type) to have height 20px
}

.SelectableItem__body {
    flex: 1;
    overflow: hidden;
}

.SelectableItem__delete {
    margin-right: 5px;
}
.SelectableItem__menu {
    margin-left: 5px;
}

.SelectableItem--selected {
    box-shadow: inset 0 0 0 2px $blue-200;
}

.SelectableItem--deactivated {
    cursor: default;
    &:hover,
    &:focus {
        background-color: $neutral-98;
    }
    .SelectableItem__name {
        color: $neutral-60;
    }
}
