@import '../../../../../../styles/partials/index.scss';

.OrganizationLog__Timeline {
    margin: 20px 0;
    overflow: auto;
    max-height: 65vh;

    .Timeline {
        margin-bottom: 20px;
    }
}

.OrganizationLog__title {
    @extend %bold18;
    color: $neutral-40;
    margin-bottom: 3px;
}
