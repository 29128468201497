@import '../../../../styles/partials/index';

.DataFeatures {
    min-width: 60vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.DataFeatures__content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    margin-top: 20px;
}

.DataFeatures__verticalRule {
    border-right: 1px solid $neutral-90;
    margin: 0 30px;
}

.DataFeatures__list {
    display: flex;
    flex-direction: column;
    min-width: 175px;
    max-width: 265px;
    flex: 1;
    overflow: auto;
    margin-right: -30px; // -30px right and 30px padding-right
    padding: 0 30px 30px 0;

    > * + * {
        margin-top: 10px;
    }
}

.DataFeatures__list__loaders {
    height: 50px;
}

.DataFeatures__Feature {
    flex: 2;
    overflow: auto;


    .Table {
        max-height: 196px;
        min-width: 0;
        background-color: $white;
    }
}

.DataFeatures__topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.DataFeatures__title {
    @extend %bold18;
}

.DataFeatures__row {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

.DataFeatures__dataSection {
    flex: 1;

    > header,
    .DataFeatures__details {
        @extend %bold14;
        color: $neutral-40;
        margin-bottom: 5px;
    }
}

.DataFeatures__details--number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    margin-right: 10px;
    color: $neutral-40;
}

.DataFeatures__inputs {
    display: flex;
    flex-direction: row;

    > .Input {
        flex: 1;
    }
}

.DataFeatures__inputs__type .Input {
    margin-right: 30px;
}

.DataFeatures__listLabel {
    @extend %bold18;
    margin-bottom: 20px;
}

.DataFeatures__searchBar {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    margin: 20px 0 10px;

    .InputBorder {
        width: 300px;
    }
}

.DataFeatures__searchLabel {
    @extend %bold18;
}

.DataFeatures__inputs__type .TextButton {
    display: block;
    margin-top: 5px;
}
