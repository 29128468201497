@import '../../../../../../styles/partials/index';

.UpdateOutputs {
    display: flex;
    height: calc(100% - 95px); // 95px for header
    margin-top: -15px;
}

.UpdateOutputs__main {
    margin-left: 30px;
    width: calc(100% - 270px); // 240px for model bar, 30px for margin

    .Button {
        margin-top: 30px;
    }
}

.UpdateOutputs__outputs {
    display: flex;
    margin: 30px 0;
    overflow: auto;

    .SelectableItem {
        margin-right: 10px;
    }
}

.UpdateOutputs__run {
    @extend %regular14;
    width: 265px;
    margin: 50px auto 0;
}

.UpdateDataset {
    .Input {
        max-width: 300px;
    }
}

.UpdateDataset__tableTitle {
    @extend %bold14;
    margin: 30px 0 10px;
}

.UpdateDataset__tableHeading {
    display: flex;
    align-items: center;
    white-space: nowrap;

    .Chip {
        margin-left: 7px;
    }
}

.UpdateDataset__oldData--empty {
    @extend %bold14;
    margin-top: 30px;
}

.UpdateDataset__linkInput {
    padding: 5px;
}
