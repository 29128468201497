@import '../../../styles/partials/index';

.DashboardImage {
    min-height: 185px;
    height: 22vh;
    width: 100%;

    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    margin: 0 0 5px;
    border: 1px solid $neutral-90;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral-60;
}
