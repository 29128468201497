@import '../../../../styles/partials/index';

.Login__Login {
    .Input {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 5px;
        }
    }
}

.Login__button {
  width: 100%;
}

.Login__microsoft .Login__button,
.Login__google .Login__button,
.Login__apple .Login__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:5px;
  background-color: $neutral-98;
  color: $neutral-40;
  font-weight: 100; // Adjust this value as per your preference

  &:hover {
    background-color: darken($neutral-98, 5%); // Darken the button color on hover by 5%
  }

  // If the button is loading (assuming you might have a 'loading' class added)
  &.loading {
    background-color: lighten($neutral-98, 5%); // Lighten the button color when loading by 5%
  }
}


// .Login__microsoft .Login__button, .Login__google .Login__button, .Login__apple .Login__button {
//   // ... [rest of your properties]
//   justify-content: flex-start;  // Start content from left
//
//   span {  // Assuming your button text is wrapped inside a <span> or similar element.
//     line-height: 1.5;  // Adjust this value if needed
//     margin-left: 10px; // Adjust this value if needed
//   }
// }
.centered-text {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 1px;
    color: $neutral-80;
}

.Login__google .button-icon, .Login__apple .button-icon {
  position: absolute;
  left: 10px;          // Adjust as needed to place icon correctly
  width: 24px;
  height: 24px;
  margin: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.Login__microsoft .button-icon {
  position: absolute;
  left: 12px;          // Adjust as needed to place icon correctly
  width: 18px;
  height: 18px;
  margin: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
