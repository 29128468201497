@import '../../../styles/partials/index';

.ProjectList {
    background-color: $white;
    padding: 0 35px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.ProjectList__header {
    width: 100%;
    margin: 30px 0;
}

.ProjectList__title {
    @extend %bold24;
}

.ProjectList__body {
    max-width: 830px;
    margin: 0 auto;

    > * {
        margin-bottom: 15px;
    }
}

.ProjectList__modal {
    min-width: 450px;
    .ModalCard__footer {
        margin-top: 10px;
    }
}

.ProjectList__empty {
    text-align: center;
    max-width: 300px;
    margin: 200px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Icon {
        margin-bottom: 10px;
    }
    .ProjectList__empty--Title {
        @extend %bold18;
        margin-bottom: 4px;
    }
    .ProjectList__empty--Text {
        @extend %regular14;
        margin-bottom: 15px;
    }
}
