@import '../../../../styles/partials/index';

.CodeEditor {
    padding: 20px 0 30px 30px;
    height: 100%;
    width: 100%;

    .Input__container {
        height: 100%;
        width: 100%;
    }

    #sourceFileName {
        width: 250px;
    }

    .CodeEditor__codeBox {
        height: calc(100% - 40px);
        width: calc(100% - 10px);
        margin-top: 10px;

        .Codebox {
            height: 100%;
            .CodeMirror {
                border: unset;
            }
            .CodeMirror,
            .CodeMirror-gutters {
                background-color: $neutral-95;
            }
        }
    }
}

.CodeEditor__header {
    display: flex;
    justify-content: space-between;

    .CodeEditor__fileMenu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 80%;
        .CodeEditor__title {
            @extend %regular14;
            color: $neutral-40;
            width: auto;
            height: 25px;
            border-radius: 5px;
            max-width: 100%;
            line-height: 25px; // keep same as height to vertically centre ^
            padding: 0 10px;
            background-color: $neutral-95;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .CodeEditor__headerSlash {
            @extend %regular14;
            color: $neutral-80;
            margin: 0 10px;
        }
    }
}
