@import '../../../../../../styles/partials/index';

.featureOverride {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 10px;

    .Input {
        width: 100%;
        margin-bottom: 5px;
    }

    &__title {
        @extend %regular14;
        width: 100%;
        padding: 4px 0;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .DashboardColors__themePreviewColor:not(.DashboardColors__themePreviewColor--noPicker) {
        outline: none;

        &:hover {
            @extend %shortTransition;
            border: 3px solid $neutral-98;
        }
    }
}

.DashboardColors__themePreviewColor--active {
    @extend %shortTransition;
    border: 3px solid $neutral-98;
    box-shadow: inset 0 0 0 1px $neutral-90;
}
