@import '../../../../styles/partials/index';

.TabBar {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: calc(100% - 30px);
    height: 40px;

    background-color: $white;
    border: 1px solid $neutral-90;
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 4px rgb(33 48 77 / 20%);

    margin: 0 15px;
}

.TabBar__iconButton {
    margin: 5px;
}

.TabBar__droppable {
    display: flex;
    align-items: center;
    overflow-x: auto;
    height: 100%;
    flex-wrap: nowrap;
    user-select: none;
}

.TabBar__draggable {
    @extend %cursor-canDrag;
    user-select: none;
    outline: none;
}

.TabBar__draggable--dragging .TabItem {
    opacity: 0.6; // this makes the nuggets look better on drag
}
