@import '../../../../styles/partials/index.scss';

.TransferProject {
    width: 475px;

    .TransferProject__title {
        display: flex;
        align-items: center;
        .Icon {
            @include svgColor($blue-500);
            margin-right: 3px;
        }
    }

    .Notification {
        margin-bottom: 20px;

        .Notification__message {
            margin-left: 15px;
        }
    }
}
