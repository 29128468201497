@import '../../../../../styles/partials/index.scss';

.UserLogo {
    display: flex;
    flex-direction: column;
    margin-left: 1px;

    .Logo {
        cursor: pointer;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 1px solid $neutral-90;
        position: relative;
        transition: ease-in-out 0.1s;

        &:hover {
            border: 3px solid $neutral-90;
        }
    }
}
.UserLogo--large {
    display: flex;
    flex-direction: column;
    margin-left: 1px;

    .Logo,
    .UserIcon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid $neutral-90;
        position: relative;
        transition: ease-in-out 0.1s;

        &:hover {
            border: 3px solid $neutral-90;
        }
    }
}

.UserLogo__uploadModal {
    width: 350px;
}

.UserLogo__uploadModal__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .Button {
        margin-left: 10px;
    }
}
