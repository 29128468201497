@import '../../../styles/partials/index';

.KPIUnit {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: space-evenly;
    align-items: center;
    > * {
        margin: 0 10px;
    }

    .KPI__label {
        text-align: center;
    }

    .KPI__value {
        text-align: center;
    }
}

.KPIUnit--narrow {
    flex-direction: column;
    flex-wrap: nowrap;
    > * {
        margin: 10px;
    }
}

.KPIUnit--smallest .KPI__value {
    @extend %bold24;
}
