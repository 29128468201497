@import '../../../../styles/partials/index';

.FeatureTableHeader {
    display: flex;
    height: 28px;
    min-width: max-content;
    align-items: center;
    text-overflow: ellipsis;

    &:hover .IconButton {
        opacity: 1;
    }

    .IconButton {
        opacity: 0;
    }

    .IconButton--selected {
        opacity: 1;
    }
}

.FeatureTableHeader__title {
    flex: 1;
    margin-right: 3px;
    line-height: 38px;

    &:focus {
        outline: none;
    }
}
