@import '../../../../../../../styles/partials/index';

.CreateUserModal {
    width: 885px;
    max-width: 100%;

    .Input {
        width: 275px;
    }

    hr {
        margin: 30px 0;
    }
}

.CreateUserModal__row {
    display: flex;
    width: 100%;
    margin-top: 20px;

    > * + * {
        margin-left: 30px;
    }
}
