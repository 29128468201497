@import '../../../../styles/partials/index';

.ConsultantOrganizationSettings {
    display: flex;
    flex-direction: column;
    height: 100%;

    .Tabs {
        margin-bottom: 30px;

        main {
            overflow: auto;
        }
    }
}

.ConsultantOrganizationSettings__header {
    display: flex;
    height: 50px;
    margin-bottom: 20px;
    align-items: center;
}
