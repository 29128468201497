@import '../../../../styles/partials/index';

.Navbar {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    z-index: 41;
    background-color: $white;

    height: 40px;
    width: 100%;
    padding: 0 24px; //30px minus icon button hit area
    border: 1px solid $neutral-90;
    border-radius: 0 0 10px 10px;
    border-top: 0;
    box-shadow: 0px 0px 4px rgba($neutral-20, 0.2);

    @include tablet {
        padding: 0px 18px; //30px minus increased hit area on tablets
    }
}

.NavBar--withSubNav {
    &.Navbar {
        border-radius: 0;
        box-shadow: none;
    }
}

.Navbar__navigationButtons {
    display: flex;
    flex-direction: row;
}

.Navbar__nav {
    display: flex;
    justify-content: center;

    .IconButton {
        box-shadow: none;
        height: 28px; //made the hit area bigger
        min-width: 28px;
        margin: 0px 3px;
        padding: 0px 7px;
        align-content: center;

        @include tablet {
            min-width: 40px;
        }
    }

    .UserIcon {
        margin: 0px 6px;
    }

    .IconButton__label {
        margin: 2px 1px 0px 3px;
        color: $neutral-60;
        font-weight: 600;
        line-height: 100%;
    }
}

.Navbar__nav--active {
    .IconButton {
        @include svgColor($blue-500);
        color: $blue-500;

        .IconButton__label {
            color: $blue-600;
        }
    }
}

.Navbar__settings {
    width: 200px;
}

.Navbar__title {
    @extend %bold14;
    white-space: nowrap;
    padding-top: 2px; //vertically centered
    margin: auto 10px;
    text-overflow: ellipsis;
    overflow: hidden;

    .Logo {
        margin-right: 4px;
        flex-shrink: 0;
    }
}

.Navbar__title--Left {
    position: absolute;
    left: 45px;
    max-width: calc(50% - 253px); //minus half of nav items width and home icon + margin
    padding-top: 0; //project chip flexbox handles vertical centering

    @include tablet {
        left: 55px;
        max-width: calc(50% - 158px); //minus half of nav items width and home icon + margin
    }
}

.Navbar__nav__menu {
    .IconButton {
        box-shadow: none;
        height: 28px;
    }

    .UserIcon {
        cursor: pointer;
    }
}

.Navbar__project {
    @extend %bold14;
    color: $neutral-20;
    box-shadow: inset 0 0 0 1px $neutral-90;
    border-radius: 5px;
    padding: 4px 8px 4px 4px;
    cursor: pointer;
    display: flex;
    align-items: center;

    animation-name: project-chip-animation;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;

    &:hover,
    &:active,
    &.Navbar__project--active {
        background-color: $neutral-95;
        box-shadow: inset 0 0 0 3px $neutral-90;
    }

}

@keyframes project-chip-animation {
    from {
        opacity: 0;
        margin-left: -35px;
    }

    to {
        opacity: 1;
        margin-left: 0;
    }
}

