@import '../../../../styles/partials/index';

.ModelInputs {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.ModelInputs__selectors {
    padding: 0 50px 12px;
    height: fit-content;
    width: calc(100% - 100px);
}

.ModelInputs__selectorsContent {
    display: flex;
    flex-direction: row;

    > * + * {
        margin-left: 10px;
    }
}

.ModelInputs__selectorsContent--empty {
    height: 57px;
    display: flex;
    align-items: center;
}

.ModelInputs__selector__warningIcon {
    @include svgColor($red-400);
}

.ModelInputs__main {
    display: flex;
    flex-grow: 1;
    width: 100%;
    max-height: calc(100% - 69px); // 69px for input selector header
    border-top: 1px solid $neutral-90;
}

.ModelInputs__empty {
    @extend %bold14;
    margin: 50px auto;
    width: fit-content;
}

.ModelInputs__previewDisplay {
    margin: 20px 0 20px 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

.ModelInputs__previewDisplay__header {
    @extend %bold14;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DashboardPreview {
    @extend %boxShadowLight;
    width: 100%;
    background: linear-gradient(180deg, $neutral-98 0%, $neutral-95 100%);
    border: 3px solid $neutral-90;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow: hidden;
    height: calc(100% - 30px);
    margin-top: 10px;
    position: relative;

    .ModelPanel {
        margin: -2px -2px -2px 0;
        height: calc(100% + 4px);
        flex-shrink: 0;
    }

    .ModelBar {
        margin: 20px 20px 20px auto;
        max-width: calc(100% - 40px); //minus left and right margins of preview area
    }

    .Notification {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}

.DashboardPreview__main {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
}

.DashboardPreview__cards {
    padding: 0 20px 20px;
    flex-grow: 1;
    min-width: 100%;
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;

    div {
        background-color: $white;
        opacity: 0.65;
        border-radius: 10px;
        filter: blur(3px);
    }

    :last-child {
        grid-column: span 2;
    }
}
