@import '../../../../../styles/partials/index';

.ModelInputConfig {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    height: 100%;
}

.ModelInputConfig__main {
    margin-top: 30px;
    width: 100%;
    width: 850px;
    max-width: calc(100% - 80px);

    hr {
        margin: 30px 0;
    }
}

.ModelInputConfig__prompt {
    @extend %regular14;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 85px auto 0;
}

.ModelInputConfig__addDescription {
    justify-content: left;
    margin-top: 20px;
}

.ModelInputConfig__subtitle {
    @extend %bold14;
    color: $neutral-40;
}

.InputRow {
    // default is 3 columns
    display: grid;
    gap: 30px;
    margin: 20px 0 10px;
    grid-template-columns: repeat(3, 1fr);
}

.InputRow--2col--right {
    :nth-child(2) {
        grid-column: span 2;
    }
}
.InputRow--1col {
    grid-template-columns: 1fr;
}
