@import '../../../../../styles/partials/index';


.DatasetSelection {
    position: relative;
    width: 275px;
}

.DatasetSelection__button {
    input {
        cursor: pointer;
    }
}
