@import '../../../../../styles/partials/index';

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: auto;

    > div {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: 30px;
        }
    }
}

.Footer__version {
    color: $neutral-70;
}

.Footer__whiteWhale {
    text-align: right;
}

.Footer__whiteWhaleLogo {
    height: 33px;
}

.Footer__modal {
    @extend %frame;
    width: 550px;
    height: 450px;
    padding: 0;
}

.Footer__modalContent {
    padding: 15px;
}
