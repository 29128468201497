@import '../../../styles/partials/index';

.Chart {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: left;
    flex: 1;
    height: 100%;
    width: 100%;

    .Loader {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
