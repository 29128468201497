@import '../../../../styles/partials/index.scss';

.PopOver {
    display: inline-block;
    position: absolute;
    text-align: left;
    padding: 15px;
    margin: 15px;
    color: $neutral-40;
    background-color: $neutral-98;
    border-style: solid;
    border-radius: $border-radius-large;
    border-color: $blue-400;
    z-index: 10;
    min-width: 200px;
    max-width: 300px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    animation-duration: 2s;
    animation-name: delay;
    a {
        @extend %semibold14;
    }
}

@keyframes delay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.PopOver__title {
    @extend %bold18;
    overflow: auto;
    word-break: break-word;
}

.PopOver--NoTitle {
    .PopOver__body {
        @extend %semibold14;
        overflow: auto;
        word-break: break-word;
    }
}

.PopOver__body {
    @extend %semibold14;
    overflow: auto;
    word-break: break-word;
    .SidePanelButton {
        display: inline-flex;
    }
}

.PopOver__button {
    margin-top: 10px;
}

.PopOver--Top::after {
    content: '';
    bottom: 100%;
    left: 50%;
    border-style: solid;
    position: absolute;
    border-color: transparent transparent $blue-400 transparent;
    border-width: 12px; // size of arrow
    margin-left: -12px; // keep same # as border-width
}

.PopOver--Right::after {
    content: '';
    top: 50%;
    left: 100%;
    border-style: solid;
    position: absolute;
    border-color: transparent transparent transparent $blue-400;
    border-width: 12px; // size of arrow
    margin-top: -12px; // keep same # as border-width
}

.PopOver--Bottom::after {
    content: '';
    top: 100%;
    left: 50%;
    border-style: solid;
    position: absolute;
    border-color: $blue-400 transparent transparent transparent;
    border-width: 12px; // size of arrow
    margin-left: -12px; // keep same # as border-width
}

.PopOver--Left::after {
    content: '';
    top: 50%;
    right: 100%;
    border-style: solid;
    position: absolute;
    border-color: transparent $blue-400 transparent transparent;
    border-width: 12px; // size of arrow
    margin-top: -12px; // keep same # as border-width
}
