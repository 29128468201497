@import '../../../../styles/partials/index';

.CurrentSVG {
    width: calc(51vw + 51vh); // keeps the original aspect ratio (612:792 = 17:22 = 85:110)
    height: calc(66vw + 66vh); // keeps the original aspect ratio
    position: absolute;
    right: 0;
    bottom: 0;
}

@keyframes currentFlow {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 2800px;
    }
}

.CurrentSVG__path {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 7px;
    stroke-linecap: round;
    animation-name: currentFlow;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.CurrentSVG__path--1 {
    stroke: $green-300;
    stroke-dasharray: 180px 20px;
    animation-duration: 1800s;
}

.CurrentSVG__path--2 {
    stroke: $blue-300;
    stroke-dasharray: 60px 20px;
    animation-duration: 4800s;
}

.CurrentSVG__path--3 {
    stroke: $orange-300;
    stroke-dasharray: 120px 20px;
    animation-duration: 3700s;
}
