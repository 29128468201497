@import '../../../../styles/partials/index';

.DashboardHeader {
    display: flex;
    flex-direction: column;
    padding: 7px 30px;
    position: relative;

    @include phone {
        padding: 7px 20px;
    }

    .ModelBar {
        @extend %boxShadowLight;
        margin-bottom: 8px;
        width: fit-content;
    }

    .FilterBar {
        @extend %boxShadowLight;
        margin: 0 5px 8px 0;
        width: fit-content;
    }
}

.DashboardHeader__titleRow {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.DashboardHeader__title {
    @extend %bold24;
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-end;

    > h2,
    h4 {
        margin: 0;
    }

    h4 {
        margin-left: 30px;
    }
}

.DashboardHeader__publicLink {
    width: max-content;

    &:not(:last-child) {
        margin-right: 10px;
    }
}

.DashboardHeader__flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    margin-right: -5px;
}

.DashboardHeader__modelBars {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    width: 100%;
}

.DashboardHeader--public {
    .DashboardHeader__titleRow * {
        z-index: 1;
    }

    .DashboardHeader__title {
        flex: 0 0 auto;
    }

    .FilterBar {
        margin: auto 0;
        @include phone {
            margin-left: auto;
        }
    }
}

@keyframes slide-down {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.DashboardHeader__color {
    animation: 1s ease slide-down;
    position: absolute;
    left: -200px;
    right: -200px;
    height: 350px;
    transition: background-color 1s;
    border-radius: 0 0 75% 100%;
}
