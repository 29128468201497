@import '../../../../styles/partials/index';


.FormPreview__title {
    @extend %bold14;
    margin-right: auto;
    margin-bottom: 5px;
    margin-top: 15px;
}

.FormPreview__description {
    @extend %regular12;
    margin-right: auto;
    font-style: italic;
    color: $neutral-60;
}

.FormPreview__content {
    width: 100%;
    overflow: auto;
    background-color: $neutral-98;
    border: 1px solid $neutral-90;
    border-radius: 15px;
    margin-top: 10px;
}
