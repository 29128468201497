@import '../../../styles/partials/index';

.FormMapping {
    padding-bottom: 50px;

    .Button .Icon {
        margin-right: 5px;
    }
}

.FormMapping__top {
    @extend %boxShadowLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 30px;
    background-color: $white;
    border-radius: 0 0 10px 10px;
    border: 1px solid $neutral-90;
    border-top-style: none;
    position: sticky;
    top: 0;
    z-index: 1;
}

.FormMapping__topSection {
    display: flex;
    align-items: center;

    // hacky, but this is the width of the div with the buttons... prevents input from becoming too large
    max-width: calc(100% - 168px);

    .Input {
        max-width: calc(100% - 10px);
    }

    > * {
        margin: 0 5px;
    }
}

.FormMapping__elements {
    width: min(900px, 70vw);
    margin: auto;
}

.FormMapping__prompt {
    width: 300px;
    margin: 30px auto 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        @extend %bold18;
        margin: 0 0 6px;
    }

    p {
        @extend %regular14;
        color: $neutral-40;
        margin-top: 0;
        text-align: center;
    }

    a {
        @extend %semibold14;
    }
}

.FormMapping__copyLink {
    display: flex;
    align-items: center;

    .IconButton {
        margin-left: 5px;
    }
}

.FormPreviewModal__preview {
    margin: 10px 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    height: 80vh;
}
