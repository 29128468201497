@import '../../../styles/partials/index';

.DashboardList {
    margin: 30px;
}

.DashboardList__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include phone {
        flex-wrap: wrap;
        align-content: flex-start;

        > :first-child {
            margin-bottom: 10px;
        }
    }
}

.DashboardList__title {
    @extend %bold24;
}

.DashboardList__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10px;
    margin-right: -20px;

    @include tablet {
        grid-template-columns: 1fr 1fr;
    }

    @include phone {
        grid-template-columns: 1fr;
    }

    .PreviewCard {
        margin: 20px 20px 0 0;
    }
}

.DashboardList__empty {
    text-align: center;
    max-width: 300px;
    margin: 200px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Icon {
        margin-bottom: 10px;
    }
    .DashboardList__empty--Title {
        @extend %bold18;
        margin-bottom: 4px;
    }
    .DashboardList__empty--Text {
        @extend %regular14;
        margin-bottom: 15px;
    }
}

.DashboardList__Modal {
    margin: 0 10px;
    width: 844px;

    > * {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .DashboardList__Row1 {
        display: flex;
        .DashBoardListInput--Description {
            flex: 1;
        }
    }
    .DashboardList__Row2 {
        @extend %bold14;
        .DashboardList__Layout {
            display: flex;
            .LayoutButton:first-child {
                margin-right: 30px;
            }
        }
    }
    .DashBoardListInput {
        margin: 0 30px 20px 0;
        width: 275px;
    }

    .CollapsibleSection .Input {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.DashboardList__Modal_split {
    display: flex;

    > * {
        flex: 1 1 0;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}
