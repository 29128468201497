@import '../../../../../styles/partials/index';

.UserSettings {
    height: 100%;
    background-color: $white;

    .Tabs {
        height: auto;

        > main {
            overflow: auto;
        }
    }
}

.UserSettingsModal {
    width: 925px;
}
