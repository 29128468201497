@import '../../../../styles/partials/index';

.PreviewCard {
    display: inline-block;
    position: relative;
    width: auto;
    margin-right: 30px;
    margin-bottom: 30px;

    .Card {
        @extend %shortTransition;
        padding: 0;
        height: 100%;

        &:hover {
            @extend %boxShadowMedium;
        }
    }

    .ScrollArea {
        max-height: 45px;
        min-height: 10px;
    }
}

.PreviewCard__main {
    padding: 20px 20px 10px;

    cursor: pointer;
    outline: none;
}
.PreviewCard__menu {
    .ContextMenuItem__type {
        .Icon {
            @include svgColor($white);
        }
    }
}

.PreviewCard__name {
    @extend %bold14;

    margin: 10px 35px 5px 0; // so it does not overlap IconButton
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.PreviewCard__description {
    color: $neutral-60;
    margin-bottom: 5px;
    padding-right: 13px;
}

.PreviewCard__button {
    @extend %shortTransition;
    position: absolute;
    right: 8px;
    opacity: 0;
}

.PreviewCard--open,
.PreviewCard:hover {
    .PreviewCard__name {
        color: $blue-600;
    }

    .PreviewCard__button {
        opacity: 1;
    }
}
