@import '../../../../styles/partials/index';

.CollectionsList__createModal {
    .Input {
        width: 375px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.CollectionsList .Loader {
    margin: 20px;
    transform: scale(0.5);
}
