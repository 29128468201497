@import '../../../../styles/partials/index';

.ButtonWithOptions {
    --box-shadow-color: #{$neutral-80};
    position: relative;

    >.IconButton {
        // icon button is position absolutely on top of .ButtonWithOptions__button so that it's hover state is separate
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 10px;
    }

    .ButtonWithOptions__label {
        color: $neutral-40;
    }
}

.ButtonWithOptions__button {
    @extend %shortTransition;

    position: relative;
    display: flex;
    align-items: center;

    height: 40px;
    padding: 10px 6px 10px 8px;

    background: $neutral-98;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px var(--box-shadow-color);

    overflow: hidden; // so loader does not overflow border radius

    outline: none;

    >.Icon {
        margin-right: 9px;
    }

    .ContextMenu__hook {
        // position the hook exactly over the iconbutton
        bottom: 10px; // 10px matches iconbutton margin
        top: 10px;
        right: 10px;
        left: unset;
        width: 20px; // matches iconbutton width
    }

    .Loader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;

        > div {
            // hacking the react-spinner library a bit (the library can only specify one colour, we need two)
            background-color: $blue-500;
        }
    }
}

.ButtonWithOptions__button--clickable {
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 0 0 3px var(--box-shadow-color);
    }

    &:active {
        box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 3px var(--box-shadow-color);
        transition: none;
    }
}

.ButtonWithOptions__button--options {
    // space for the options icon button
    padding-right: 30px;
}

.ButtonWithOptions__button--selected {
    --box-shadow-color: #{$blue-500};
}

.ButtonWithOptions__button--selected > .Icon {
    // using the child combinator (>) to only select the Icon, not the IconButton
    @include svgColor($blue-500);
}

.ButtonWithOptions__button--failed > .Icon {
    @include svgColor($red-400);
}

.ButtonWithOptions__name {
    @extend %bold14;
    flex: 1;
    color: $neutral-50;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ButtonWithOptions__button--selected > .IconButton {
    // using the child combinator (>) to only select the Icon, not the IconButton
    @include svgColor($blue-500);
}

.ButtonWithOptions__button--failed > .IconButton {
    @include svgColor($red-400);
}
