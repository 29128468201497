@import '../../../../../styles/partials/index';

.DatasetMappingTable {
    display: flex;
    flex-grow: 1;
    color: $neutral-50;
    border-radius: 0;
    height: Calc(100% - 51px);

    input {
        border-color: $neutral-90 !important;
    }
}

.DatasetMappingTable__MappingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.DatasetMappingTable__MappingContainer--empty {
    @extend %regular14;
    display: flex;
    align-items: center;
    justify-content: center;
}
