@import '../../../styles/partials/index';

.Login {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        height: 90px;
        width: 90px;
        margin: 0 auto 30px;
    }

}

.Login__Card {
    width: 350px;
    height: auto;
    padding: 20px;
}

.Login__Title {
    @extend %bold14;
    margin-bottom: 10px;
    color: $blue-500;
    text-align: left;
    text-decoration: none;
}

.Login__Footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 24px;
    width: 100%;
}

.Login__buttons {
    display: flex;
    margin-top: 20px;
}

.Login__button--right {
    margin-left: auto;
}
