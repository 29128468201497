@import '../../../../styles/partials/index';

.FeatureBasket {
    @extend %cursor-canDrag;
    background-color: $white;
    border: 1px solid $neutral-90;
    border-radius: 5px;
    max-height: 250px;
    position: relative;
    transition: border 0.15s ease-in;
    padding: 10px;
    width: 200px;

    &:hover {
        border: 1px solid $blue-400;

        .FeatureBasket__closeButton {
            opacity: 1;
        }
    }
}

.FeatureBasket__title {
    @extend %bold14;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 23px; // space for the "remove" button in top right
}

.FeatureBasket__featureName {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 28px);
    max-height: 34px;
}

.FeatureBasket__sourceName {
    text-align: center;
    color: $neutral-60;
}

.FeatureBasket__closeButton {
    opacity: 0;
    position: absolute;
    right: 8px;
    top: 8px;
}

.FeatureBasket__featureType {
    @include svgColor($neutral-85);
    margin-right: auto;
}

.FeatureBasket__featureType--changed {
    @include svgColor($blue-200);
}

.FeatureBasket__preview {
    // For whatever reason, the dnd library we were using was taking a screenshot of the wrong area
    // of the screen.  This div, positioned exactly over the feature basket, makes the dnd libary take
    // the correct preview screenshot while dragging.  I don't know why this works and attaching the ref
    // somewhere else did not work.
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    pointer-events: none;
}

.FeatureBasket--preview {
    opacity: 0.4;
}

.FeatureBasketOperation__operations {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    .Chip {
        margin: 0 3px;
    }
}

.gradientColours {
    @extend %regular14;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 0;

    .Chip {
        margin: 0 7px;
    }
}
