@import '../../../styles/partials/index';

.ModelsList {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 20px 0 20px 30px;
    overflow: hidden;

    @include phone {
        flex-direction: column;
        overflow: auto;
        padding: 20px 30px;
    }

    @include tablet {
        flex-direction: column;
        overflow: auto;
        padding: 20px 30px;
    }

    .Card {
        margin-right: 30px;
        padding: 30px;

        @include phone {
            width: 100%;
            margin: 0px 0px 20px 0px;
        }

        @include tablet {
            width: 100%;
            margin: 0px 0px 20px 0px;
        }
    }

    .SelectableItem {
        margin: 10px 0;
    }
}

.ModelsList__card {
    display: flex;
    flex-direction: column;
}
.ModelsList__card--package {
    width: 50%;
}
.ModelsList__card__header {
    @extend %bold18;
    display: flex;
    justify-content: space-between;
}
.ModelsList__card__title {
    @extend %bold18;
    text-align: center;
    margin-bottom: 10px;
}
.ModelsList__card__text {
    margin-bottom: 20px;
    a {
        @extend %bold14;
    }
}
.createPackage {
    align-items: center;
}

.ModelsList__card__prompt {
    @extend %regular14;
    max-width: 300px;
    margin: 10px auto 30px;
    text-align: center; // aligns the text & images
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ModelsList__card__list {
    height: calc(100% - 185px); // 35px for header, 150px for prompt and its margin
    .ModelsList__card__scheduledRuns {
        margin-left: 30px;
        padding: 5px;
        .SelectableItem__body {
            .SelectableItem__name {
                @extend %semibold14;
            }
        }
    }
}

.ModelsList__card__listItem--error {
    .SelectableItem__type {
        @include svgColor($red-400);
    }
}

.ModelsList__card__listItem--building {
    .SelectableItem__type {
        @include svgColor($blue-300);
        animation: rotate 2s linear 0s infinite;
    }
}

.ModelsList__card__listItem--empty {
    height: 57px;
    margin: 10px 0;
    background-color: $neutral-98;
    border-radius: 8px;
    opacity: 0.75;

    &:first-child {
        opacity: 1;
    }

    &:last-child {
        opacity: 0.5;
    }
}
