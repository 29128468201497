@import '../../../../../../styles/partials/index';

.OrganizationGeneral {
    @extend %semibold14;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .Input {
        width: 313px;
    }

    > * + * {
        margin-left: 30px;
    }
    .OrganizationLogo {
        .Logo {
            height: 40px;
            width: 40px;
        }
    }
}
