@import '../../../../styles/partials/index';

.CreateModel {
    display: flex;
    flex-direction: column;
    width: 70vw;
    max-width: 1000px;
    margin: 50px auto;

    hr {
        margin: 30px 0;
    }

    .Icon {
        margin: 0 auto;
    }
}

.CreateModel__inputs {
    display: flex;
}

.CreateModel__name {
    margin-right: 30px;
    width: 30%;
    max-width: 300px;
}

.CreateModel__description {
    flex-grow: 1;
}

.CreateModel__selectTitle {
    @extend %bold18;
    margin: 20px 0 15px;
}

.CreateModel__selectInfo {
    color: $neutral-40;
    width: 300px;
    margin-bottom: 30px;
}
