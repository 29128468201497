@import '../../../../../styles/partials/index';

.UserRoles {
    .UserRoles__header {
        @include svgColor($neutral-40);
        background-color: $neutral-95;
        display: inline-flex;
        border-radius: 10px;
        justify-content: space-evenly;
        width: 100%;

        .projects__header,
        .admin__header {
            margin: 10px 0 10px 15px;
            align-items: center;
            display: inline-flex;
        }
        .projects__header {
            flex: 1;
        }
        .admin__header {
            flex: 3;
        }

        .text {
            @extend %bold14;
            color: $neutral-20;
        }

        .Icon {
            margin-right: 10px;
        }
    }

    .UserRoles__options {
        display: inline-flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 16px 0 0 0;
    }

    .UserRoles__radio {
        flex: 1;
        margin: 0 0 0 15px;
    }

    .UserRoles__checkBox {
        flex: 3;
        margin: 0 0 0 15px;
        display: inline-flex;
    }
    .col1 {
        margin-right: 26px;
    }

    .Input,
    .RadioOption {
        margin-bottom: 10px;
        margin-top: 0;
    }

    .Input__label,
    .RadioOption__label {
        @extend %semibold14;
        color: $neutral-40;
    }
}

.UserRoles__select {
    display: flex;
    align-items: center;

    .Input {
        width: 275px;
        margin-bottom: 20px;
    }

    .IconButton {
        margin-left: 5px;
    }
}

.UserRoles__description {
    @extend %regular14;
    font-style: italic;
    color: $neutral-60;
    margin-left: 30px;
    width: 275px;
}

.UserRoles__table {
    margin-top: 10px;
}

.UserRoles--disabled {
    .UserRoles__options,
    .Input__label,
    .RadioOption__label {
        opacity: 0.6;
    }
}
