@import '../../styles/partials/index';

.Data {
    &.Page__main {
        // aaaaa page styles are really bad, they put the classname .Data on 2 different divs!
        height: calc(100% - 40px); // - 60px margin
        width: calc(100% - 45px); // - 15px margin on left, 30px margin on right
        margin: 20px 30px 20px 15px;

        @include tablet {
            margin: 10px 20px 20px;
            width: calc(100% - 40px);
        }

        @include phone {
            margin: 10px 20px 20px;
            width: calc(100% - 40px);
        }
    }
}

.Data__main {
    height: 100%;
    width: 100%;
    padding: 20px 30px 30px;
}

.data__empty {
    text-align: center;
    max-width: 300px;
    margin: 200px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Icon {
        margin-bottom: 10px;
    }

    .data__empty--Title {
        @extend %bold18;
        margin-bottom: 4px;
    }

    .data__empty--Text {
        @extend %regular14;
        margin-bottom: 15px;
    }
}

.DataEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.DataEmpty__message {
    @extend %regular14;
    max-width: 250px;
}

.DataEmpty__message--bold {
    @extend %bold14;
}
