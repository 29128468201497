/* stylelint-disable selector-max-compound-selectors */
/* TODO: these styles should be refactored */
@import '../../../styles/partials/index';

.DataTabs {
    height: calc(100% - 54px);

    .Tabs {
        height: 100%;

        > main {
            height: calc(100% - 34px);
            overflow: auto;
        }
    }

    .DataFeatures {
        height: 100%;
    }
}

.DataTabs__header {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .Input {
        overflow: auto;
    }

    .DatasetType {
        margin-left: 10px;
    }

    .Sharing {
        margin: 0 0 0 auto;
    }
}
