@import '../../../../../styles/partials/index';

.SelectFunction {
    height: calc(100% - 110px); // 110px for header
    padding: 30px;

    .PackageFunctionSelect {
        margin-top: 20px;
    }

    .Button {
        margin-top: 30px;
    }
}

.SelectFunction__title {
    @extend %bold14;
    margin-top: 15px;
}

.SelectFunction__info {
    @extend %regular14;
    color: $neutral-40;
    margin-left: 18px;
    margin-bottom: 20px;
}

.UpdateInputs {
    height: calc(100% - 95px); // 95px for header
    margin-top: -15px;
}
