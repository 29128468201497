@import '../../styles/partials/index';

.AccessSettings {
    height: calc(100% - 40px);

    .Card {
        margin: 20px;
        height: 100%;
        padding: 30px;
        padding-bottom: 20px;
        overflow: auto;
    }
}

.AccessSettings__frameLabel { // TODO: This is meant to copy frame styles... is there something we can do?
    @extend %bold14;
    color: $neutral-20;
    margin-bottom: 5px;
}

.AccessSettings__title {
    @extend %bold20;
    margin: 10px;
}

.AccessSettings__breadcrumb {
    @extend %regular12;
    margin: 8px 0;
}
