@import '../../../../../styles/partials/index';

.Grid {
    height: 100%;

    .react-grid-item {
        &:hover {
            z-index: 2;

            .react-resizable-handle {
                opacity: 1;
            }
        }

        &.resizing {
            z-index: 3;
        }

        &.cssTransforms {
            transition-property: none;
        }

        &.react-draggable-dragging {
            transition: none;
            z-index: 5;
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grabbing;

            .Unit__contentWrapper {
                cursor: move !important; /* fallback if grab cursor is unsupported */
                cursor: grabbing !important;
            }

            .Form {
                pointer-events: none;
            }
        }

        &.react-grid-placeholder {
            background: $neutral-80;
            opacity: 0.25;
            transition-duration: 100ms;
            z-index: 4;
            border-radius: 11px;
            user-select: none;
            pointer-events: none;
        }

        > .react-resizable-handle {
            position: absolute;
            width: 20px;
            height: 20px;
            bottom: 0;
            right: 0;
            opacity: 0;
            background: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Atoms' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Other/Resize-Icon-Copy-3' transform='translate(-1.000000, -1.000000)' fill='%23A1ABC8'%3E%3Cg id='Group' transform='translate(1.000000, 1.000000)'%3E%3Ccircle id='Oval' cx='13' cy='1' r='1'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-2' cx='1' cy='13' r='1'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-3' cx='7' cy='13' r='1'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-4' cx='13' cy='7' r='1'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-5' cx='7' cy='7' r='1'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-position: bottom right;
            padding: 1px 6px 6px 1px;
            background-repeat: no-repeat;
            background-origin: content-box;
            cursor: se-resize;
            transition: opacity 0.5s;

            &:active {
                background-position: bottom right;
                background-repeat: no-repeat !important;
                background-origin: content-box;
                background: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Atoms' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Other/Resize-Icon-Copy-2' transform='translate(-1.000000, -1.000000)' fill='%233FA9ED'%3E%3Cg id='Group' transform='translate(1.000000, 1.000000)'%3E%3Ccircle id='Oval1' cx='13' cy='1' r='1'%3E%3C/circle%3E%3Ccircle id='Oval1-Copy-2' cx='1' cy='13' r='1'%3E%3C/circle%3E%3Ccircle id='Oval1-Copy-3' cx='7' cy='13' r='1'%3E%3C/circle%3E%3Ccircle id='Oval1-Copy-4' cx='13' cy='7' r='1'%3E%3C/circle%3E%3Ccircle id='Oval1-Copy-5' cx='7' cy='7' r='1'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    .DashboardGrid__draggableHandle {
        // background-color: $neutral-98; // remember to remove dis

        &:hover {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
        }

        &:active {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grabbing;
        }
    }
}

.react-grid-layout {
    height: 100%;
    position: relative;
    transition: height 200ms ease;

    &.animated > .react-grid-item.cssTransforms {
        transition-property: transform;
    }
}

.react-grid-item {
    height: 100%;
    z-index: 1;
    transition: all 100ms ease;
    transition-property: left, top;
}

.react-grid-gap {
    z-index: 0;
    display: block;
}
