@import '../../../../../styles/partials/index';


.OrganizationList {
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 15px;
    }

}