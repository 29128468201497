.message-container {
    display: flex;
    align-items: flex-end;
    margin: 2rem 0;
    gap: 0.5rem;
}

.thinking-container {
    display: flex;
    align-items: flex-end;
    margin: 2rem 0;
    gap: 0.5rem;
    
}

.flex-row-reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.flex-row {
    flex-direction: row;
    justify-content: flex-end;
}

.w-screen {
    width: 80vw;
    overflow: hidden;
}

.chat-start .chat-bubble {
    color: #21304d;
    background-color: #dfe2ee;
    font-size: 1.2rem;
    font-stretch: condensed;
    font-weight: 100;
    padding: 2rem 2rem 1rem 2rem;
    font-family: nunito-light;
}

.chat-end .chat-bubble {
    color: #21304d;
    background-color: #86e3c6;
    font-size: 1.2rem;
    padding: 1rem 2rem 0.5rem 2rem;
    font-family: nunito-regular;
}

.chat-bubble {
  padding-top: 5px;
    /* Define a default style for chat bubble here */
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-xs {
    font-size: 0.75rem;
    opacity: 0.8;
    padding-top: 5px;
}

.avatar .w-8 {
    width: 2rem;
    border: 1px solid #2E3748; /* Color of slate-400 */
    border-radius: 50%;
}

.avatar .w-6 {
    width: 1.5rem;
    height: 100%;
    margin: auto;
}
